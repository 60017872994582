import React, { useEffect, useState } from "react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Container,
  Flex,
  Stack,
  Heading,
  Text,
  Box,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { toastAlert } from "../ToastAlerts";

import { usePrompt } from "../../blocker";

import axios from "axios";
import FormData from "form-data";
import { cloneDeep } from "lodash";

import AdditionalContactModal from "./Assets/AdditionalContactModal";

import {
  clientQuestions,
  fundingQuestions,
  medicalQuestions,
  questionnaireQuestions,
  therapyQuestions,
} from "./Variables/questions";

import FormPage from "./FormPage";
import FormSubmissionPage from "./FormSubmissionPage";
import moment from "moment";

const ReferralForm = () => {
  const [referralData, setReferralData] = useState({
    client: {},
    contacts: [],
    medical_information: {},
    therapy_information: {},
    funding_information: {},
    questionnaire: {},
  });
  const [submitStatus, setSubmitStatus] = useState({
    submitting: false,
    submitted: false,
    submitMessage: `Don't worry, we've got you... This form takes 10-15 seconds to
    process so even if you think nothing is happening, we are working
    hard behind the scenes for you to get your form submitted ASAP. Sit
    tight and whatever you do, don't exit this page!`
  });

  const [storageKey, setStorageKey] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { nextStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });

  // Data submission handler
  useEffect(() => {
    if (activeStep === 4) {
      addReferral();
      // setSubmitStatus({ submitting: true, submitted: true });
    }
  }, [referralData]);

  useEffect(() => {
    if (storageKey !== "") {
      setSubmitStatus({...submitStatus, submitMessage: "Almost there! We're just uploading your attachments. Please do not close the page."})
      sendPatientAttachments(storageKey);
    }
  }, [storageKey]);

  // Remove unfilled questions from the referral data
  const removeEmptyFields = (obj) => {
    const newObj = cloneDeep(obj);
    Object.keys(newObj).forEach((category) => {
      if (!Array.isArray(newObj[category])) {
        Object.keys(newObj[category]).forEach((question) => {
          if (
            newObj[category][question] === "" ||
            newObj[category][question] === undefined
          ) {
            delete newObj[category][question];
          }
          if (newObj[category][question] instanceof Date) {
            newObj[category][question] = moment(
              newObj[category][question]
            ).format("YYYY-MM-DD");
          }
          if (
            question === "client_reports" ||
            question === "behavioural_support_documentation"
          ) {
            if (newObj[category][question].length === 0) {
              newObj[category][question] = false;
            } else {
              newObj[category][question] = true;
            }
          }
        });
      }
    });
    return newObj;
  };

  // function to transfer data to server
  const addReferral = () => {

    // setTimeout(() => setSubmitStatus({ submitted: true, submitting: true }), 5000);


    const data = removeEmptyFields(referralData);
    axios
      .post("/api/referrals", data)
      .then((res) => {
        console.error(res);
        if (res.status === 206) {
          setStorageKey(res.data.key);
        } else {
          setSubmitStatus({ ...submitStatus,submitting: true, submitted: true });
        }
      })

      .catch((err) => {
        console.error(err.response);
        toastAlert(
          "error",
          "Form Submission Error",
          "There was an error submitting the form, please contact GRP directly."
        );
        setSubmitStatus({ ...submitStatus, submitted: false, submitting: false });
      });
  };

  const sendPatientAttachments = (storage_key) => {
    const formData = new FormData();
    referralData.medical_information.client_reports.forEach((file) =>
      formData.append("file_list", file)
    );
    referralData.questionnaire.behavioural_support_documentation.forEach(
      (file) => formData.append("file_list", file)
    );
    axios
      .post(`api/referral_attachment?storage_key=${storage_key}`, formData)
      .then((res) => {
        console.log(res.json);
        setSubmitStatus({ 
        submitMessage: `Don't worry, we've got you... This form takes 10-15 seconds to
        process so even if you think nothing is happening, we are working
        hard behind the scenes for you to get your form submitted ASAP. Sit
        tight and whatever you do, don't exit this page!`, 
        submitting: true,
        submitted: true });
      })
      .catch((err) => {
        setSubmitStatus({ 
          submitMessage: `Don't worry, we've got you... This form takes 10-15 seconds to
        process so even if you think nothing is happening, we are working
        hard behind the scenes for you to get your form submitted ASAP. Sit
        tight and whatever you do, don't exit this page!`, 
        submitting: false, 
        submitted: false });
        toastAlert(
          "error",
          "Something went wrong",
          "There was an error uploading your attachments. Please contact GRP directly."
        );
      });
  };

  //
  const onSubmit = (name, data) => {
    setReferralData({ ...referralData, [name]: data });
    if (activeStep === 4) {
      setSubmitStatus({ submitting: true, submitted: false });
    } else {
      nextStep();
    }
  };

  const contacts = referralData.contacts;

  const buttonChild = (
    <Button w="100%" onClick={onOpen}>
      {contacts
        ? [
            contacts.length > 0
              ? `${contacts.length} Added - Add another?`
              : "Add additional contact",
          ]
        : "Add additional contact"}
    </Button>
  );

  const clientDetailsForm = (
    <FormPage
      name="client"
      referralData={referralData.client}
      contacts={referralData.contacts}
      questions={clientQuestions}
      buttonChildren={buttonChild}
      onSubmit={onSubmit}
    />
  );
  const MedicalForm = (
    <FormPage
      name="medical_information"
      referralData={referralData.medical_information}
      questions={medicalQuestions}
      onSubmit={onSubmit}
    />
  );
  const TherapyForm = (
    <FormPage
      name="therapy_information"
      referralData={referralData.therapy_information}
      questions={therapyQuestions}
      onSubmit={onSubmit}
    />
  );

  const FundingForm = (
    <FormPage
      name="funding_information"
      referralData={referralData.funding_information}
      questions={fundingQuestions}
      onSubmit={onSubmit}
    />
  );

  const questionnaireForm = (
    <FormPage
      name="questionnaire"
      referralData={referralData.questionnaire}
      questions={questionnaireQuestions}
      onSubmit={onSubmit}
    />
  );

  const steps = [
    { label: "Client Details", content: clientDetailsForm },
    { label: "Medical Information", content: MedicalForm },
    { label: "Therapy Details", content: TherapyForm },
    { label: "Funding Info", content: FundingForm },
    { label: "Questionnaire", content: questionnaireForm },
  ];

  const addAdditionalContact = (newContact) => {
    setReferralData({
      ...referralData,
      contacts: [...referralData.contacts, newContact],
    });
  };

  usePrompt("Form data will be lost if exited.", !submitStatus.submitted);

  return (
    <Container maxW={"5xl"} pt={3}>
      <Flex>
        <Box
          mx={"auto"}
          minH={"80vh"}
          mb={8}
          direction="column"
          minW={"100%"}
          bg="white"
          rounded="xl"
          boxShadow={"2xl"}
        >
          {!submitStatus.submitting ? (
            <Stack spacing={5} py={{ base: 6, md: 8 }} px={8}>
              <Stack align={"center"}>
                <Heading
                  fontSize={"3xl"}
                  textAlign={"center"}
                  fontWeight="normal"
                >
                  Referral Form
                </Heading>
                <Text fontSize={"lg"} color={"gray.600"}>
                  You're on your way to receiving great Speech Therapy!
                </Text>
              </Stack>

              <Steps
                activeStep={activeStep}
                responsive={true}
                colorScheme="facebook"
                mx="auto"
                // onClickStep={(e) => setStep(e)}
                onClickStep={(e) =>
                  e < activeStep
                    ? setStep(e)
                    : toastAlert(
                        "warning",
                        "Click 'Next' to proceed with the form"
                      )
                }
              >
                {steps.map(({ label, content }) => (
                  <Step label={label} key={label}>
                    <Box maxW="100%">{content}</Box>
                  </Step>
                ))}
              </Steps>
            </Stack>
          ) : (
            <FormSubmissionPage submitStatus={submitStatus} />
          )}
        </Box>
      </Flex>
      <AdditionalContactModal
        isOpen={isOpen}
        onClose={onClose}
        addAdditionalContact={addAdditionalContact}
      />
    </Container>
  );
};

export default ReferralForm;
