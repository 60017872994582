import React, { useMemo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Flex,
  Text,
  Badge,
  IconButton,
  chakra,
  Tooltip,
  Select,
  Stack,
  VStack,
  HStack,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import moment from "moment";

import { FiUserX, FiUserPlus } from "react-icons/fi";
import { RiUserSettingsLine, RiUser3Line } from "react-icons/ri";

import { updateUser } from "../actions/system";
import TableSkeleton from "./TableSkeleton";

const UsersTable = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(false);
  const cancelRef = useRef();

  useEffect(() => {
    setGlobalFilter(props.searchValue || undefined);
  }, [props.searchValue]);

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "first_name",
        Cell: (original) => (
          <>
            <HStack>
              {original.cell.row.original.is_staff ? (
                <RiUserSettingsLine size={26} />
              ) : (
                <RiUser3Line size={26} />
              )}
              <VStack align="left" pl={2}>
                <Text fontSize="md" letterSpacing={0.8} fontWeight="semibold">
                  {original.cell.row.original.first_name}{" "}
                  {original.cell.row.original.last_name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {original.cell.row.original.is_staff
                    ? "Administrator"
                    : "Logistics Personnel"}
                </Text>
              </VStack>
            </HStack>
          </>
        ),
      },

      {
        Header: "# of Linked Clinicians",
        accessor: "linked_clinicians_count",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm" textAlign="center">
            {original.cell.row.original.linked_clinicians_count} linked
            Clinicians
          </Text>
        ),
      },
      {
        Header: "Joined",
        accessor: "created",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {original.cell.row.original.created
              ? moment(original.cell.row.original.created).format(
                  "ddd DD MMM, YYYY h:mm a "
                )
              : "Never"}
          </Text>
        ),
      },
      {
        Header: "Last Login",
        accessor: "last_login",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {original.cell.row.original.last_login
              ? moment(original.cell.row.original.last_login).format(
                  "ddd DD MMM, YYYY h:mm a "
                )
              : "Never"}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "is_staff",
        Cell: (original) => (
          <Badge
            colorScheme={
              original.cell.row.original.api_key_confirmed ? "green" : "red"
            }
            px={3}
            py={1}
            rounded="2xl"
            border="2px solid black"
          >
            {original.cell.row.original.api_key_confirmed ? "VALID" : "INVALID"}
          </Badge>
        ),
      },
      {
        id: "active",
        Header: "Action",
        accessor: "active",
        Cell: (original) => (
          <HStack>
            <IconButton
              colorScheme={original.cell.row.original.active ? "red" : "green"}
              aria-label={
                original.cell.row.original.active
                  ? "Deactive user"
                  : "Activate user"
              }
              border="2px solid black"
              icon={
                original.cell.row.original.active ? <FiUserX /> : <FiUserPlus />
              }
              onClick={() => {
                setSelectedUser(original.cell.row.original);
                onOpen();
              }}
            />
          </HStack>
        ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    pageCount,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data: props.users,
      initialState: {},
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Box mt={3} rounded="xl" overflowX={{ base: "scroll", md: "hidden" }}>
        {props.users.length <= 0 ? (
          <TableSkeleton />
        ) : (
          [
            <Table variant="grpTable" {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>,
          ]
        )}
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        textColor="white"
        mt={3}
      >
        <Flex gap={2}>
          <Tooltip label="First Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              variant="grpButton"
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex
          alignItems="center"
          bg="pink.100"
          paddingX={5}
          paddingY={1}
          rounded="xl"
          border="2px solid black"
          textColor="black"
          gap={3}
        >
          <Text flexShrink="0" mr={1}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>

          <Select
            display={{ base: "none", xl: "inherit" }}
            w={32}
            borderColor="black"
            borderWidth="2px"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex gap={2}>
          <Tooltip label="Next Page">
            <IconButton
              variant="grpButton"
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
            />
          </Tooltip>
        </Flex>
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {selectedUser.active || false
                ? "Deactivate User"
                : "Activate User"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to perform this action?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={selectedUser.active || false ? "red" : "green"}
                onClick={() => props.updateUser(selectedUser, onClose)}
                ml={3}
              >
                {selectedUser.active || false ? "Deactivate" : "Activate"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.system.users,
});

export default connect(mapStateToProps, { updateUser })(UsersTable);
