import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../actions/auth";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";

import { HiEye, HiEyeOff } from "react-icons/hi";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

import { Navigate, useLocation } from "react-router";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .required("Please enter your email"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(32, "Too Long!")
    .required("Please enter your password"),
});

const Login = (props) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      props.login(values.email, values.password, formik.setSubmitting);
    },
    validationSchema: LoginSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  if (props.auth.isAuthenticated) {
    return (
      <Navigate
        to={location.state?.from ? location.state.from.pathname : "/kpis"}
      />
    );
  }

  return (
    <Container
      maxW={{ base: "sm", md: "md" }}
      py={{ base: "6vh" }}
      px={{ base: "2", sm: "8" }}
    >
      <Stack spacing="8" mt={6}>
        <Box py={10} px={"10"} bg={"white"} boxShadow={"xl"} borderRadius="xl">
          <Stack textAlign="center" p={2}>
            <Heading fontSize="4xl">Staff Portal</Heading>
          </Stack>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="6">
                <Stack spacing="3">
                  <FormControl
                    id="email"
                    name="email"
                    isInvalid={formik.errors.email && formik.touched.email}
                  >
                    <FormLabel htmlFor="email" m={0}>
                      Email
                    </FormLabel>
                    <Input
                      borderWidth="2px"
                      borderColor="black"
                      type="email"
                      autoComplete="username"
                      _hover={{ borderColor: "black" }}
                      _focus={{
                        boxShadow: "0 0 0 3px pink.800",
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="password"
                    name="password"
                    isInvalid={
                      formik.errors.password && formik.touched.password
                    }
                  >
                    <FormLabel m={0}>Password</FormLabel>
                    <InputGroup>
                      <Input
                        borderWidth="2px"
                        borderColor="black"
                        _hover={{ borderColor: "black" }}
                        _focus={{
                          boxShadow: "0 0 0 3px pink.800",
                        }}
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <InputRightElement h={"full"}>
                        <Icon
                          cursor="pointer"
                          as={showPassword ? HiEye : HiEyeOff}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {formik.errors.password}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

                <Button
                  variant="grpButton"
                  fontWeight="medium"
                  type="submit"
                  isLoading={formik.isSubmitting}
                >
                  Sign in
                </Button>
              </Stack>
            </form>
          </FormikProvider>
        </Box>
      </Stack>
    </Container>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
