import * as Yup from "yup";

import { Heading, Text, Stack, Link } from "@chakra-ui/react";

import moment from "moment-timezone";

const ausPhoneRegExp = new RegExp(
  /^0[2-47-8]{1}[0-9]{8}$/
);

 // /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/ old regex

const contact_update_options = [
  "Emails",
  "Session Notes",
  "Reports",
  "Session Schedule",
  "Reminders",
  "Therapy Resources",
];

const diagnoses = [
  "Autism Level 1",
  "Autism Level 2",
  "Autism Level 3",
  "Asperger's Syndrome",
  "Attention Deficit Disorder",
  "Attention Deficit Hyperactive Disorder",
  "Acquired Brain Injury (ABI)",
  "Traumatic Brain Injury (TBI)", 
  "Intellectual Disability",
  "Developmental Delay",
  "Global Developmental Delay",
  "Down syndrome",
  "Cerebral Palsy",
  "Fragile X Syndrome",
  "Chromosomal Disorder (Other)",
  "Anxiety Disorder",
  "Depression",
  "Schizophrenia",
  "Bipolar Disorder",
  "Obsessive Compulsive Disorder",
  "Psychosocial Disorder (Other)",
  "Communication Disorder/ Aphasia from Stroke (Mild)",
  "Communication Disorder/ Aphasia from Stroke (Moderate)",
  "Communication Disorder/ Aphasia from Stroke (Severe)",
  "Spina Bifida",
  "Deafness",
  "Hearing Impairment",
  "Tourette Syndrome",
  "Epilepsy",
  "Parkinson's Disease",
  "Motor Neurone Disease",
  "Multiple Sclerosis",
  "Muscular Dystrophy",
  "Dementia",
  "Primary Progressive Aphasia (PPA)",
  "Dysarthria",
  "Dysphonia/ Voice - Mild Impairment/ Injury",
  "Dysphonia/ Voice - Moderate Impairment/ Injury",
  "Dysphonia/ Voice - Severe Impairment/ Injury",
  "Transgender Voice",
  "Apraxia of Speech/ Dyspraxia",
  "Dyslexia",
  "Tracheostomy",
  "Laryngectomy",
  "Videofluoroscopy",
  "Paediatric Dysphagia",
  "Adult Dysphagia"
];

export const bookingTimeOptions = [
  {
    day: "Monday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
  {
    day: "Tuesday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
  {
    day: "Wednesday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
  {
    day: "Thursday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
  {
    day: "Friday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
  {
    day: "Saturday",
    timeslots: ["8am-10am", "10am-12pm", "12pm-2pm", "2pm-4pm", "4pm-6pm"],
  },
];

export const clientQuestions = [
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Client Details</Heading>
        <Text>
          The below questions are in regards to the client (The one receiving
          the Speech Pathology service)
        </Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "first_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "First Name",
    yup: Yup.string().required("Please enter the client's first name").trim(),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "last_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Last Name",
    yup: Yup.string().required("Please enter the client's last name").trim(),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "preferred_name",
    type: "text",
    question: "Preferred Name",
    placeholder: "Optional",
    yup: Yup.string().trim(),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "pronoun",
    type: "select",
    placeholder: " ",
    options: ["He", "She", "They", "Them", "Other"],
    question: "Pronoun",
    yup: Yup.string().trim(),
    gridParams: { colSpan: { base: 1, md: 2 } },
  },
  {
    name: "date_of_birth",
    type: "date",
    question: "Date of Birth",
    yup: Yup.date()
      .max(moment(), "Date of birth cannot be in the future")
      .required("Please enter the client's date of birth"),
    DOB: true,
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "email",
    type: "text",
    question: "Email Address",
    yup: Yup.string()
      .trim()
      .email("Email is not valid")
      .required("Please enter the client's email address"),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "phone_number",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Phone Number",
    yup: Yup.string()
      .trim()
      .required("Please enter a valid Australian phone number")
      .matches(ausPhoneRegExp, "Phone number is invalid."),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },

  {
    name: "address",
    type: "text",
    question: "Address",
    yup: Yup.string()
      .trim()
      .required("Please enter the client's address")
      .trim(),
    gridParams: { colSpan: { base: 1, md: 8 } },
  },
  {
    name: "suburb",
    type: "text",
    question: "Suburb",
    yup: Yup.string().trim().required("Please enter suburb"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "state",
    type: "select",
    options: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
    question: "State",
    yup: Yup.string().trim().required("Please select state"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "post_code",
    type: "text",
    question: "Postcode",
    yup: Yup.string()
      .trim()
      .min(4, "Invalid Postcode")
      .max(4, "Invalid Postcode")
      .required("Please enter postcode"),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "type_of_premises",
    type: "select",
    options: [
      "Private Residence",
      "Supported Independant Living",
      "Nursing Home",
      "Disability Accomodation",
      "Aged Care Residence",
      "Other",
    ],
    question: "Type of Premises",
    yup: Yup.string().trim().required("Please select type of premises"),
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "preferred_language",
    type: "text",
    question: "Preferred Language other than English",
    placeholder: "Optional",
    yup: Yup.string().trim(),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },

  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Next of Kin/ Guardian Details</Heading>
        <Text>
          The below questions are in regards to the Next of Kin or Guardian.
        </Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "nok_first_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "First Name",
    yup: Yup.string()
      .trim()
      .required("Please enter the Next of Kin/ Guardian's first name"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "nok_last_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Last Name",
    yup: Yup.string()
      .trim()
      .required("Please enter the Next of Kin/ Guardian's last name"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "nok_relationship",
    type: "text",
    question: "Relation to Client",
    yup: Yup.string().trim().required("Please state relation to client"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "nok_email",
    type: "text",
    question: "Email Address",
    yup: Yup.string()
      .trim()
      .email("Email is not valid")
      .required("Please enter the Next of Kin/ Guardian's email address"),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "nok_phone_number",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Phone Number",
    yup: Yup.string()
      .trim()
      .required("Please enter a valid Australian phone number")
      .matches(ausPhoneRegExp, "Phone number is invalid."),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "nok_share_information",
    type: "radio",
    question:
      "Would you like us to share Documents/Updates with the Next of Kin/ Guardian?",
    formControlDirection: true,
    yup: Yup.bool().required("Please select an option"),

    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "nok_which_information",
    options: contact_update_options,
    question:
      "What documents/updates would you like us to share with the Next of Kin/ Guardian?",
    type: "multiselect",
    yup: Yup.array().when("nok_share_information", {
      is: true,
      then: Yup.array()
        .min(1, "Please select an option")
        .required("Please select at least one option"),
    }),

    displayDependantOn: "nok_share_information",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    type: "textOnly",
    text: <Heading fontSize="2xl">Request Preferences</Heading>,
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "assessment_type",
    question: "Which type of assessment is needed?",
    type: "select",
    options: ["Communication", "Swallow", "Both"],
    yup: Yup.string().trim().required("Please select an option."),
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "report_type",
    question:
      "Does the client require a comprehensive report or summary report?",
    helpText:
      "Standard report outlines outcomes and goals, where as a comprehensive report details communication goals and NDIS budget forcast for review meeting> 'Summary report outlines'",
    type: "select",
    options: ["Comprehensive", "Summary"],
    yup: Yup.string().trim().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 7 } },
  },
];

export const additionalContactQuestions = [
  {
    name: "first_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "First Name",
    yup: Yup.string()
      .trim()
      .required("Please enter the additional contact's first name"),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "last_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Last Name",
    yup: Yup.string()
      .trim()
      .required("Please enter the additional contact's last name"),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "relation_to_client",
    type: "text",
    question: "Relation to Client",
    yup: Yup.string().trim().required("Please state relation to client"),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "email",
    type: "text",
    question: "Email Address",
    yup: Yup.string()
      .trim()
      .email("Email is not valid")
      .required("Please enter the additional contact's email address"),
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "phone_number",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Phone Number",
    yup: Yup.string()
      .trim()
      .required("Please enter the additional contact's Australian phone number")
      .matches(ausPhoneRegExp, "Phone number is invalid."),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "share_information",
    type: "radio",
    question: "Would you like us to share Documents/Updates with this contact?",

    yup: Yup.bool().required("Please select an option"),

    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "which_information",
    options: contact_update_options,
    question:
      "What documents/updates would you like us to share with this contact?",
    type: "multiselect",
    yup: Yup.array().when("share_information", {
      is: true,
      then: Yup.array()
        .min(1, "Please select an option")
        .required("Please select at least one option"),
    }),

    displayDependantOn: "share_information",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
];

export const medicalQuestions = [
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Medical Details</Heading>
        <Text>Please complete the below questions</Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "client_diagnosis",
    type: "multiselect",
    params: {
      multiple: true,
      options: diagnoses.sort(),
      create: true,
    },
    question: "Client's Diagnosis",
    yup: Yup.array()
      .min(1, "Please select an option")
      .required("Please answer this question"),
    helpText:
      "Please select at least one option. If a diagnosis is not listed, you can type it into the text box.",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "tracheostomy_or_laryngectomy",
    type: "select",
    options: [
      "Tracheostomy", 
      "Laryngectomy",
      "None of the above" 
    ],
    formControlDirection: true,
    question: "Do you or your client have one of the following?",
    yup: Yup.string().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "frequent_chest_infections",
    type: "radio",
    question: "Has the client had frequent chest infections?",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "palliative_care_plan",
    type: "radio",
    question: "Is your client on a palliative care plan?",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "flagged_diagnoses",
    type: "radio",
    question:
      "Does your client have any of the following diagnoses? Past Stroke / Acquired brain injury / Traumatic brain injury / Surgery to head or neck area, Parkinson's Disease / Multiple Sclerosis, Motor Neurone Disease, Dementia",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "feeding_tube",
    type: "radio",
    question: "Does your client use a feeding tube to support their nutrition?",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "client_has_allergies",
    type: "radio",
    question: "Does the client have any allergies?",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_allergies",
    type: "text",
    question: "What is the client allergic to?",
    yup: Yup.string()
      .trim()
      .when("client_has_allergies", {
        is: true,
        then: Yup.string()
          .trim()
          .required("Please provide the client's allergies"),
      }),
    displayDependantOn: "client_has_allergies",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "client_speech_goals",
    type: "text",
    question: "In one sentence, what are the client's Speech Pathology goals?",
    yup: Yup.string()
      .trim()
      .required("Please state the client's speech pathology goals.")
      .trim(),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Communication/ Voice</Heading>
        <Text>Please complete the below questions</Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_is_verbal",
    type: "radio",

    question: "Is the client verbal?",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_verbal_details",
    type: "text",
    displayDependantOn: "client_is_verbal",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== undefined,
    placeholder: "Optional",
    question:
      "Please provide any additional details of the client's verbal ability.",
    yup: Yup.string().trim(),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_best_communicates",
    type: "select",
    options: [
      "Verbal (Single Words)",
      "Verbal (Short Phrases)",
      'Verbal (Sentences)',
      'Limited Verbal + Communication Aid (AAC)',
      "Sign Language (AUSLAN)",
      "Key Word Sign (KWS)",
      "Communication Aid (AAC)",
      "Non verbal"
    ],
    question: "Please select how your client best communicates",
    gridParams: { colSpan: { base: 1, md: 12 } },
    yup: Yup.string().trim().required("Please select an option"),
  },
  {
    name: "communication_aid_explanation",
    question: "Please clarify the client's communication aid requirements",
    type: "text",
    displayDependantOn: "client_best_communicates",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue === "Communication Aid (AAC)",
    yup: Yup.string()
      .trim()
      .when("client_best_communicates", {
        is: (client_best_communicates) =>
          client_best_communicates === "Communication Aid (AAC)",

        then: Yup.string()
          .trim()
          .required(
            "Please clarify the client's communication aid requirements"
          ),
      }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Eating, Drinking and Feeding</Heading>
        <Text>Please complete the below questions</Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "diet_consistancy",
    question: "Please clarify the client's current diet and fluid consistency",
    type: "text",
    yup: Yup.string()
      .trim()
      .required("Please enter the client's diet and fluid consistency"),
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "eat_drink_feed_add_information",
    type: "text",
    question: "Please share any additional information regarding the concerns",
    placeholder: "Optional",
    yup: Yup.string().trim(),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "client_reports",
    type: "fileInput",
    question: "Please upload any relevant medical reports",
    helpText: "You may only upload 2 files here, Max upload file size: 5MB each",
    yup: Yup.array(),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
];

export const therapyQuestions = [
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl">Therapy Details</Heading>
        <Text>Please complete the below questions</Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "session_availability",
    type: "bookingTimeSelector",
    question:
      "Please select at least 3 times throughout the week that suit you for ongoing therapy sessions.",
    helpText:
      `The more time slots you are able to provide, the sooner we can expedite a clinician to you! If you are booking from a different timezone, please consider availability slots are reflective of ${moment.tz('Australia/Melbourne').format('z')}.`,
    description: "",
    yup: Yup.array()
      .min(
        3,
        (e) => `Please select ${e.min - e.originalValue.length} more timeslots`
      )
      .required("Please select at least 3 timeslots"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_can_participate",
    type: "radio",
    question:
      "Can your client sit down and participate in a one-to-one interaction? ie. formal assessment",
    yup: Yup.bool().required("Please answer this question."),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_has_attention_difficulties",
    type: "radio",
    question:
      "Does your client have attentional difficulties or behavioral difficulties that limit their participation in tasks?",
    yup: Yup.bool().required("Please answer this question."),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "client_attentional_difficulties_details",
    type: "text",
    placeholder:
      "Please provide more information about the client's attentional deficit difficulties or behavioral difficulties",
    displayDependantOn: "client_has_attention_difficulties",
    yup: Yup.string()
      .trim()
      .when("client_has_attention_difficulties", {
        is: true,
        then: Yup.string().trim().required("Please provide more information"),
      }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "contact_client_for_booking",
    type: "select",
    options: [
      "Client", 
      "Parent",
      "Family Member",
      "Facility",
      "Support Coordinator"
    ],
    question: "Who should be contacted to arrange appointments?",
    yup: Yup.string().required("Please select an option"),

    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "session_frequency",
    type: "select",
    options: [
      "Weekly",
      "Fortnightly",
      "Monthly",
      "3 Months",
      "Other",
      "Unsure",
    ],
    question: "How frequently would you like Speech Pathology sessions?",
    gridParams: { colSpan: { base: 1, md: 6 } },
    yup: Yup.string().trim().required("Please select an option"),
  },
  {
    name: "therapy_location",
    question: "Where would you like to have your sessions?",
    type: "select",
    options: [
      "Home",
      "School",
      "Nursing Home",
      "SRS",
      "Day Program",
      "Work",
      "Private residence",
      "Supported Independent Living",
      "Disability accomodation",
      "Aged care residence",
    ],
    yup: Yup.string().trim().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "location_name",
    type: "text",
    question: "Name of Location",
    displayDependantOn: "therapy_location",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,
    yup: Yup.string()
      .trim()
      .when("therapy_location", {
        is: (e) => e !== "Home",
        then: Yup.string()

          .trim()
          .required("Please state the name of this location."),
      }),
    helpText: "Eg. Name of School or Nursing Home",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "location_address",
    type: "text",
    question: "Address",
    displayDependantOn: "therapy_location",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,
    yup: Yup.string()
      .trim()
      .when("therapy_location", {
        is: (e) => e !== "Home",
        then: Yup.string()
          .trim()
          .trim()
          .required("Please enter the location's address"),
      }),
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    type: "textOnly",
    text: (
      <Text fontWeight={"medium"}>
        Who is the best person at this location to speak to about arranging the
        client's speech pathology sessions? (can not be reception)
      </Text>
    ),
    displayDependantOn: "therapy_location",

    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "location_contact_fullname",
    type: "text",
    question: "Contact Full name",
    displayDependantOn: "therapy_location",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,
    yup: Yup.string()
      .trim()
      .when("therapy_location", {
        is: (e) => e !== "Home",
        then: Yup.string()
          .trim()
          .required("Please state the location contact's full name"),
      }),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "location_contact_phone_number",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Contact Phone Number",
    displayDependantOn: "therapy_location",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,
    yup: Yup.string()
      .trim()
      .when("therapy_location", {
        is: (e) => e !== "Home",
        then: Yup.string()
          .trim()
          .required("Please enter the location contact's phone number")
          .matches(ausPhoneRegExp, "Phone number is invalid."),
      }),
    gridParams: { colSpan: { base: 1, md: 4 } },
  },

  {
    name: "location_contact_share_information",
    type: "radio",
    question: "Would you like us to share Documents/Updates with this contact?",
    yup: Yup.bool().when("therapy_location", {
      is: (e) => e !== "Home",
      then: Yup.bool().required("Please select an option"),
    }),
    formControlDirection: true,
    displayDependantOn: "therapy_location",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue !== "Home" &&
      displayDependantOnValue !== undefined,

    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "location_contact_which_information",
    options: contact_update_options,
    question:
      "What documents/updates would you like us to share with this contact?",
    type: "multiselect",
    yup: Yup.array().when("location_contact_share_information", {
      is: true,
      then: Yup.array()
        .min(1, "Please select an option")
        .required("Please select at least one option"),
    }),

    displayDependantOn: "location_contact_share_information",

    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "has_support_coordinator",
    type: "radio",
    question: "Do you have a support coordinator?",
    yup: Yup.bool().required("Please answer the question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "support_coordinator_first_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Support Coordinator's First Name",
    yup: Yup.string()
      .trim()
      .when("has_support_coordinator", {
        is: true,
        then: Yup.string()
          .trim()
          .trim()
          .required("Please enter the Support Coordinator's First Name"),
      }),
    displayDependantOn: "has_support_coordinator",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "support_coordinator_last_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Support Coordinator's Last Name",
    yup: Yup.string()
      .trim()
      .when("has_support_coordinator", {
        is: true,
        then: Yup.string()
          .trim()
          .trim()
          .required("Please enter the Support Coordinator's Last Name"),
      }),
    displayDependantOn: "has_support_coordinator",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "support_coordinator_organisation",
    type: "text",
    question: "Support Coordinator's Organisation",
    yup: Yup.string()
      .trim()
      .when("has_support_coordinator", {
        is: true,
        then: Yup.string()
          .trim()
          .trim()
          .required("Please enter the Support Coordinator's Organisation"),
      }),
    displayDependantOn: "has_support_coordinator",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "support_coordinator_email",
    type: "text",
    question: "Support Coordinator's Email Address",
    yup: Yup.string()
      .trim()
      .when("has_support_coordinator", {
        is: true,
        then: Yup.string()
          .trim()
          .trim()
          .required("Please enter the Support Coordinator's Email Address")
          .email("Please enter a valid email address"),
      }),
    displayDependantOn: "has_support_coordinator",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "support_coordinator_phone",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Support Coordinator's Direct Phone Number",
    yup: Yup.string()
      .trim()
      .when("has_support_coordinator", {
        is: true,
        then: Yup.string()
          .trim()
          .required(
            "Please enter the Support Coordinator's Direct Phone Number"
          )
          .matches(ausPhoneRegExp, "Please enter a valid phone number"),
      }),
    displayDependantOn: "has_support_coordinator",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "support_coordinator_share_information",
    type: "radio",
    question: "Would you like us to share Documents/Updates with this contact?",
    yup: Yup.bool().when("has_support_coordinator", {
      is: true,
      then: Yup.bool().required("Please select an option"),
    }),
    displayDependantOn: "has_support_coordinator",

    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "support_coordinator_which_information",
    options: contact_update_options,
    question:
      "What documents/updates would you like us to share with this contact?",
    type: "multiselect",
    yup: Yup.array().when("support_coordinator_share_information", {
      is: true,
      then: Yup.array()
        .min(1, "Please select an option")
        .required("Please select at least one option"),
    }),

    displayDependantOn: "support_coordinator_share_information",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "telehealth",
    question:
      "Are you open to telehealth sessions if it means your client can see a Speech Pathologist faster?",
    type: "radio",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "telehealth_has_device",
    question:
      "Do you have a tablet, smart phone or laptop which has the following features: A webcam, speakers, strong internet connection and a microphone?",
    type: "radio",
    yup: Yup.bool().when("telehealth", {
      is: true,
      then: Yup.bool()
        .required("Please select an option")
        .oneOf(
          [true],
          "You cannot proceed with Telehealth without a device. Please change your telehealth answer to No"
        ),
    }),
    displayDependantOn: "telehealth",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    text: (
      <>
        <Stack gap={2}>
          <Heading fontSize={"xl"}>GRP Requires a Team Approach</Heading>
          <Text>
            A telehealth facilitator must be appointed in order for the initial
            consult and every session thereafter (ie. Sibling, parent, carer,
            support worker) in order to progress with this referral. This person
            should be someone who knows the client best.{" "}
          </Text>
          <Text>
            Prior to each scheduled session, the speech pathologist will brief
            the telehealth facilitator on the session goals and explain their
            involvement.
          </Text>
          <Text>
            The speech pathologist will support and guide the telehealth
            facilitator during each session. The speech pathologist will rely on
            their input and ability to listen to the directive in order to
            ensure that assessments and ongoing sessions are conducted in the
            most beneficial and evidence-based manner.
          </Text>
        </Stack>
      </>
    ),
    type: "textOnly",
    displayDependantOn: "telehealth_has_device",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "telehealth_facilitator_name",
    type: "text",
    inputParams: { style: { textTransform: "capitalize" } },
    question: "Telehealth Facilitator's Full Name",
    yup: Yup.string()
      .trim()
      .when("telehealth_has_device", {
        is: true,
        then: Yup.string()
          .trim()
          .required("Please enter the name of the facilitator")
          .trim(),
      }),
    displayDependantOn: "telehealth_has_device",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "telehealth_facilitator_relation_to_client",
    type: "text",
    question: "Telehealth Facilitator's Relationship to Client",
    yup: Yup.string()
      .trim()
      .when("telehealth_has_device", {
        is: true,
        then: Yup.string()
          .trim()
          .required(
            "Please enter the Telehealth Facilitator's relationship to the client"
          ),
      }),
    displayDependantOn: "telehealth_has_device",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },
  {
    name: "telehealth_facilitator_email",
    type: "text",
    displayDependantOn: 'telehealth_has_device',
    question: "Telehealth Facilitator's Email Address",
    yup: Yup.string()
      .trim()
      .when("telehealth_has_device", {
        is: true, 
        then: Yup.string().trim().email("Email is not valid")
        .required("Please enter the Telehealth Facilitator's email address")
      }),
      
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "telehealth_facilitator_phone",
    type: "ausPhoneNumber",
    helpText: "With area code, no spaces.",
    question: "Telehealth Facilitator's Direct Phone Number",
    yup: Yup.string()
      .trim()
      .when("telehealth_has_device", {
        is: true,
        then: Yup.string()
          .trim()
          .required(
            "Please enter the Telehealth Facilitator's direct contact number"
          )
          .matches(ausPhoneRegExp, "Please enter a valid phone number"),
      }),
    displayDependantOn: "telehealth_has_device",
    gridParams: { colSpan: { base: 1, md: 6 } },
  },

  {},
];

export const fundingQuestions = [
  {
    type: "textOnly",
    text: <Heading fontSize="2xl">NDIS Information</Heading>,
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "ndis",
    question: "Do you have NDIS funding?",
    type: "radio",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "ndis_number",
    type: "text",
    inputParams: { type: "number" },
    question: "NDIS Account Number",
    yup: Yup.string()
      .trim()
      .when("ndis", {
        is: true,
        then: Yup.string()
          .trim()
          .min(9, "Invalid NDIS Account Number - Too short")
          .max(9, "Invalid NDIS Account Number - Too long")
          .required("Please enter the NDIS Account Number"),
      }),
    displayDependantOn: "ndis",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "ndis_start_date",
    type: "date",
    question: "NDIS Plan Start Date",
    yup: Yup.date().when("ndis", {
      is: true,
      then: Yup.date().required("Please input the NDIS Plan Start Date"),
    }),
    displayDependantOn: "ndis",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "ndis_end_date",
    type: "date",
    question: "NDIS Plan End Date",
    yup: Yup.date().when("ndis", {
      is: true,
      then: Yup.date()
        .required("Please input the NDIS Plan End Date")
        .min(
          Yup.ref("ndis_start_date"),
          "Plan end date must be after plan start date"
        ),
    }),
    displayDependantOn: "ndis",
    gridParams: { colSpan: { base: 1, md: 4 } },
  },
  {
    name: "ndis_funding_amount",
    type: "currency",
    question: "NDIS funding amount allocated for Speech Pathology?",
    yup: Yup.number().when("ndis", {
      is: true,
      then: Yup.number()
        .positive("This funding amount must be a positive number")
        .required(
          "Please enter in the amount of funding allocated to Speech Pathology"
        ),
    }),
    displayDependantOn: "ndis",
    gridParams: { colSpan: { base: 1, md: 5 } },
  },

  {
    name: "ndis_funding_management_type",
    question: "How are the funds managed?",
    type: "select",
    options: [
      "Self-managed (GRP invoices the client)",
      "Plan managed (GRP invoices the financial intermediary)",
      "NDIA managed (GRP claim off the portal)",
      "Private Client",
    ],
    yup: Yup.string()
      .trim()
      .when("ndis", {
        is: true,
        then: Yup.string().trim().required("Please select an option"),
      }),
    displayDependantOn: "ndis",
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    name: "ndis_invoicing_email_address",
    type: "text",
    question: "Email address to send invoices to",
    displayDependantOn: "ndis",
    yup: Yup.string()
      .trim()
      .when("ndis", {
        is: true,
        then: Yup.string()
          .trim()
          .email("Please enter valid email address")
          .required(
            "Please enter the email address you would like to receive invoices to"
          ),
      }),
    gridParams: { colSpan: { base: 1, md: 3 } },
  },
  {
    type: "textOnly",
    text: (
      <Heading fontSize="2xl">Service Agreement & Cancellation Policy</Heading>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    type: "textOnly",
    text: (
      <Text color="gray.600" textAlign="left">
        At GRP Speech Pathology, we plan activities around the client’s goals for
        every appointment. We also limit the number of clients seen each day so
        we can maintain a high-quality service. Thus, it is important that you
        are aware of GRP Speech Pathology’s cancellation policy before
        confirming your ongoing therapy with us.
      </Text>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "cancellation_policy",
    question: "I have read and I accept the",
    link: "cancellation policy?",
    href: "https://www.grpspeechpathology.com.au/bookings/#booking-policies",
    type: "linkedCheckbox",
    yup: Yup.object({
      viewed: Yup.bool()
        .isTrue("Please read the cancellation policy.")
        .required("Please read the cancellation policy."),
      accepted: Yup.bool()
        .isTrue("Please accept the cancellation policy.")
        .required("Please accept the cancellation policy."),
    }),

    gridParams: { colSpan: { base: 1, md: 12 } },
    helpText: (
      <Text>
        More information regarding our cancellation and pricing policies can be
        found <Link color="blue.700" textDecoration={"underline"}>here</Link>
      </Text>
    ),
  },
  {
    name: "client_can_sign_service_agreement",
    question: "Can the client sign their own service agreement?",
    type: "radio",
    yup: Yup.bool().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "service_agreement_signee_fullname",
    question: "Who is the NOK/Guardian who will sign on their behalf?",
    placeholder: "Please enter signee's full name",
    type: "text",
    displayDependantOn: "client_can_sign_service_agreement",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue === false,
    yup: Yup.string()
      .trim()
      .when("client_can_sign_service_agreement", {
        is: false,
        then: Yup.string()
          .trim()
          .required("Please enter the name of the signee")
          .trim(),
      }),
    gridParams: { colSpan: { base: 1, md: 7 } },
  },
  {
    name: "service_agreement_signee_email",
    question: "Email address of the signee",
    type: "text",
    displayDependantOn: "client_can_sign_service_agreement",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue === false,
    yup: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .when("client_can_sign_service_agreement", {
        is: false,
        then: Yup.string()
          .trim()
          .email("Please enter a valid email address")
          .required("Please enter the email address of the signee")
          .trim(),
      }),
    gridParams: { colSpan: { base: 1, md: 5 } },
  },
  {
    name: "service_agreement_signee_present",
    question: "Will this person be at the initial appointment?",
    type: "radio",
    displayDependantOn: "client_can_sign_service_agreement",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue === false,
    yup: Yup.bool().when("client_can_sign_service_agreement", {
      is: false,
      then: Yup.bool().required("Please select an option"),
    }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
];

export const questionnaireQuestions = [
  {
    type: "textOnly",
    text: (
      <>
        <Heading fontSize="2xl" textAlign={"left"}>
          Questionnaire
        </Heading>
      </>
    ),
  },
  {
    name: "referred_from",
    question: "Where did you hear about us?",
    type: "select",
    options: [
      "Expo",
      "Social Media",
      "Google",
      "Word of mouth",
      "Support Coordinator",
      "Plan Manager",
      "Other",
    ],
    yup: Yup.string().trim().required("Please select an option"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "referred_from_des",
    question: "Please let us know where you heard about us.",
    type: "text",
    displayDependantOn: "referred_from",
    displayTriggerValue: (displayDependantOnValue) =>
      displayDependantOnValue === 'Other',
    yup: Yup.string().trim().when("referred_from", {
      is: (e) => e === "Other",
      then: Yup.string().trim().required("Please tell us where you heard about us")
    }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    type: "textOnly",
    text: (
      <>
        <Heading mb={4} fontSize="2xl" textAlign={"left"}>
          Risk Assessment
        </Heading>
        <Text mb={4} color="gray.600" textAlign="left">
          As we are a mobile service, the safety of our clinicians during
          community sessions is paramount. Please note that the outcome of this
          checklist will determine whether or not we are able to progress with
          this referral. Without the completion of the document below we will be
          unable to progress with your booking.
        </Text>
      </>
    ),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "person_responsible_for_safety",
    type: "text",
    formControlDirection: true,
    placeholder: "Please answer",
    question:
      "Who will be present for the sessions that is responsible for the safety and welfare of the client?",
    yup: Yup.string().trim().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "sealed_road",
    type: "radio",
    question: "Is the road to your property sealed (e.g. bitumen)?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "house_visable",
    type: "radio",
    question: "Is the house visible from the street?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "safe_place_to_park",
    type: "radio",
    question: "Is there a safe / close place to park?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "adequate_lighting",
    type: "radio",
    question: "Does the property have adequate lighting inside and outside?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "emergency_vehicle_access",
    type: "radio",
    question:
      "In the event of an emergency, is the property accessible for emergency vehicles from more than one direction?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "isolated_property",
    type: "radio",
    question: "Is the property in an isolated area?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "gate_to_property",
    type: "radio",
    question: "Is there a gate to the property?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "hazards",
    type: "radio",
    question: "Are there any hazards/obstructions entering the house?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "smoking_environment",
    type: "radio",
    question: "Is the home a smoking environment?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "weapons",
    type: "radio",
    question: "Are there any weapons or firearms on the property?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "infectious_diseases",
    type: "radio",
    question: "Are there any known infections/ illnesses in the house?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "pets",
    type: "radio",
    question:
      "Are there any animals with open access to the front of the property or inside the house?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "pets_kept_away",
    type: "radio",
    question:
      "Can the animal/s be kept locked away from therapist during visit?",
    yup: Yup.bool().when("pets", {
      is: true,
      then: Yup.bool().required("Please enter this question"),
    }),
    displayDependantOn: "pets",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "manual_handling_required",
    type: "radio",
    question:
      "Are there any manual handling (e.g. lifting) or other safety concerns?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "mobile_coverage",
    type: "radio",
    question: "Is there mobile phone coverage in the area?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "shoes_allowed_inside",
    type: "radio",
    question:
      "Is it acceptable for staff to wear shoes inside your house during visits?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  // {
  //   name: "any_other_concerns",
  //   type: "text",
  //   question:
  //     "If there are any other concerns for the safety and health of staff in your home environment, please state them here:",
  //   placeholder: "Eg. Mold in house, etc.",
  //   formControlDirection: true,
  //   yup: Yup.string().trim(),
  //   gridParams: { colSpan: { base: 1, md: 12 } },
  // },
  {
    name: "under_the_influence",
    type: "radio",
    question:
      "Is there evidence that anyone at home may be under the influence of alcohol or drugs?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "dhs_involvement",
    type: "radio",
    question:
      "Are there any DHS/Protective Services involvement with the client/family?",
    yup: Yup.bool().required("Please enter this question"),

    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "dhs_involvement_explained",
    type: "text",
    placeholder: "Please answer",
    formControlDirection: true,
    question:
      "Please provide basic information of DHS/Protective Services involvement",
    yup: Yup.string().when("dhs_involvement", {
      is: true,
      then: Yup.string().trim().required("Please enter this question"),
    }),

    displayDependantOn: "dhs_involvement",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "home_aggression",
    type: "radio",
    question:
      "Does anyone that may be present at the time of the home visit have a history of aggression, violent, disturbed, inappropriate, or offensive behaviour?",
    yup: Yup.bool().required("Please enter this question"),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "aggression_triggers",
    question:
      "What are the known or suspected triggers for periods of escalation/heightened emotional state? (This is critical for us to be aware of when introducing new therapy tools eg: Thomas the Tank Engine might be a trigger that leads to physical outbursts)",
    type: "text",
    yup: Yup.string()
      .trim()
      .when("home_aggression", {
        is: (e) => e === true,
        then: Yup.string().required("Please enter this question"),
      }),
    displayDependantOn: "home_aggression",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "aggression_person_types",
    type: "text",
    question:
      "Are these behaviours more prevalent when engaging with new people? (male versus female, young versus old?)",
    yup: Yup.string().when("home_aggression", {
      is: (e) => e === true,
      then: Yup.string().required("Please enter this question"),
    }),
    displayDependantOn: "home_aggression",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },

  {
    name: "aggressive_incidents_count",
    question:
      "How many incident reports/near miss reports are filed each week in relation to the behaviours of concern?",
    type: "text",
    displayDependantOn: "home_aggression",
    yup: Yup.number().when("home_aggression", {
      is: (e) => e === true,
      then: Yup.number().required("Please enter this question"),
    }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "danger_to_staff_property",
    type: "radio",
    question:
      "Do you hold concerns regarding the safety of Speech Pathologist’s property when the client is at baseline or in a heightened state? (ie. iPads, phones etc)",
    displayDependantOn: "home_aggression",
    yup: Yup.bool().when("home_aggression", {
      is: (e) => e === true,
      then: Yup.bool().required("Please enter this question"),
    }),
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "assistance_available",
    type: "radio",
    question:
      "Can there be a dedicated staff/family member present in the therapy space to assist (as directed) for the duration of the session?",
    yup: Yup.bool().when("home_aggression", {
      is: (e) => e === true,
      then: Yup.bool().required("Please enter this question"),
    }),
    displayDependantOn: "home_aggression",
    gridParams: { colSpan: { base: 1, md: 12 } },
  },
  {
    name: "behavioural_support_documentation",
    type: "fileInput",
    question:
      "If there is a current behavioural support plan and/or staff safety plan, please upload it here:",
    yup: Yup.array(),
    gridParams: { colSpan: { base: 1, md: 12 } },

    displayDependantOn: "home_aggression",
  },
];

const languages = [
  "Abkhazian",
  "Afar",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragonese",
  "Armenian",
  "Assamese",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbaijani",
  "Bambara",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bihari languages",
  "Bislama",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Burmese",
  "Catalan, Valencian",
  "Central Khmer",
  "Chamorro",
  "Chechen",
  "Chichewa, Chewa, Nyanja",
  "Chinese",
  "Church Slavonic, Old Bulgarian, Old Church Slavonic",
  "Chuvash",
  "Cornish",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Divehi, Dhivehi, Maldivian",
  "Dutch, Flemish",
  "Dzongkha",
  "English",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "French",
  "Fulah",
  "Gaelic, Scottish Gaelic",
  "Galician",
  "Ganda",
  "Georgian",
  "German",
  "Gikuyu, Kikuyu",
  "Greek (Modern)",
  "Greenlandic, Kalaallisut",
  "Guarani",
  "Gujarati",
  "Haitian, Haitian Creole",
  "Hausa",
  "Hebrew",
  "Herero",
  "Hindi",
  "Hiri Motu",
  "Hungarian",
  "Icelandic",
  "Ido",
  "Igbo",
  "Indonesian",
  "Interlingua (International Auxiliary Language Association)",
  "Interlingue",
  "Inuktitut",
  "Inupiaq",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Kinyarwanda",
  "Komi",
  "Kongo",
  "Korean",
  "Kwanyama, Kuanyama",
  "Kurdish",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Letzeburgesch, Luxembourgish",
  "Limburgish, Limburgan, Limburger",
  "Lingala",
  "Lithuanian",
  "Luba-Katanga",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Manx",
  "Maori",
  "Marathi",
  "Marshallese",
  "Moldovan, Moldavian, Romanian",
  "Mongolian",
  "Nauru",
  "Navajo, Navaho",
  "Northern Ndebele",
  "Ndonga",
  "Nepali",
  "Northern Sami",
  "Norwegian",
  "Norwegian Bokmål",
  "Norwegian Nynorsk",
  "Nuosu, Sichuan Yi",
  "Occitan (post 1500)",
  "Ojibwa",
  "Oriya",
  "Oromo",
  "Ossetian, Ossetic",
  "Pali",
  "Panjabi, Punjabi",
  "Pashto, Pushto",
  "Persian",
  "Polish",
  "Portuguese",
  "Quechua",
  "Romansh",
  "Rundi",
  "Russian",
  "Samoan",
  "Sango",
  "Sanskrit",
  "Sardinian",
  "Serbian",
  "Shona",
  "Sindhi",
  "Sinhala, Sinhalese",
  "Slovak",
  "Slovenian",
  "Somali",
  "Sotho, Southern",
  "South Ndebele",
  "Spanish, Castilian",
  "Sundanese",
  "Swahili",
  "Swati",
  "Swedish",
  "Tagalog",
  "Tahitian",
  "Tajik",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Tibetan",
  "Tigrinya",
  "Tonga (Tonga Islands)",
  "Tsonga",
  "Tswana",
  "Turkish",
  "Turkmen",
  "Twi",
  "Uighur, Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venda",
  "Vietnamese",
  "Volap_k",
  "Walloon",
  "Welsh",
  "Western Frisian",
  "Wolof",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang, Chuang",
  "Zulu",
];
