import React from "react";

import { MultiSelect } from "chakra-multiselect";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftAddon,
  GridItem,
  Radio,
  RadioGroup,
  Flex,
  Spacer,
  InputLeftElement,
  FormHelperText,
} from "@chakra-ui/react";

import { DatePicker } from "./Assets/DatePicker";
import BookingTimeSelector from "./Assets/BookingTimeSelector";
import FileInput from "./Assets/FileInput";
import LinkCheckBox from "./Assets/LinkCheckBox";

const Question = ({ question, formik }) => {
  const handleRadioChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const conditionallyDisplay = (question) =>
    question.displayDependantOn && [
      question.displayTriggerValue !== undefined
        ? [
            question.displayTriggerValue(
              formik.values[question.displayDependantOn]
            )
              ? "visable"
              : "none",
          ]
        : [formik.values[question.displayDependantOn] ? "visable" : "none"],
    ];

  switch (question.type) {
    case "radio":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            id={question.name}
            name={question.name}
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <Flex
              direction={question.formControlDirection ? "column" : "row"}
              justify="between"
            >
              <FormLabel m={0} pe={3}>
                {question.question}
              </FormLabel>
              <Spacer />
              <RadioGroup
                name={question.name}
                value={formik.values[question.name]}
                onChange={(e) => handleRadioChange(question.name, e === "true")}
              >
                <Stack direction={"row"} spacing={4}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage>{formik.errors[question.name]}</FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "text":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            id={question.name}
            name={question.name}
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <Flex
              direction={question.formControlDirection ? "row" : "column"}
              justify="between"
            >
              <FormLabel>{question.question}</FormLabel>
              <Spacer />
              <Input
                placeholder={question.placeholder}
                value={formik.values[question.name]}
                onChange={formik.handleChange}
                {...question.inputParams}
              />
            </Flex>
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage>{formik.errors[question.name]}</FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "currency":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            id={question.name}
            name={question.name}
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <Flex
              direction={question.formControlDirection ? "row" : "column"}
              justify="between"
            >
              <FormLabel>{question.question}</FormLabel>
              <Spacer />
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.600"
                  fontSize="1.1em"
                  children="$"
                  zIndex={0}
                />
                <Input
                  type="number"
                  placeholder={question.placeholder}
                  value={formik.values[question.name]}
                  onChange={formik.handleChange}
                  {...question.inputParams}
                />
              </InputGroup>
            </Flex>
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage>{formik.errors[question.name]}</FormErrorMessage>
          </FormControl>
        </GridItem>
      );

    case "select":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <FormLabel>{question.question}</FormLabel>
            <Select
              id={question.name}
              value={formik.values[question.name]}
              onChange={formik.handleChange}
              defaultValue={"Select Option"}
              {...question.params}
            >
              <option
                hidden
                disabled
                value={"Select Option"}
                key={"Select Option"}
              ></option>
              {question.options.map((option) => (
                <option key={option}>{option}</option>
              ))}
            </Select>
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage textAlign={"left"}>
              {formik.errors[question.name]}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "multiselect":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <FormLabel>{question.question}</FormLabel>
            <MultiSelect
              id={question.name}
              options={question.options}
              value={formik.values[question.name]}
              onChange={(e) => formik.setFieldValue(question.name, e)}
              {...question.params}
            />
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage textAlign={"left"}>
              {formik.errors[question.name]}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "ausPhoneNumber":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            id={question.name}
            name={question.name}
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <FormLabel>{question.question}</FormLabel>
            <InputGroup>
            {/* <InputLeftAddon>+61</InputLeftAddon> */}
              <Input
                type="tel"
                value={formik.values[question.name]}
                onChange={formik.handleChange}
              />
            </InputGroup>
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage textAlign={"left"}>
              {formik.errors[question.name]}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "linkedCheckbox":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <LinkCheckBox question={question} formik={formik} />
        </GridItem>
      );
    case "date":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <FormControl
            id={question.name}
            name={question.name}
            isInvalid={formik.errors[question.name] && formik.submitCount > 0}
          >
            <FormLabel>{question.question}</FormLabel>
            <DatePicker question={question} formik={formik} />
            <FormHelperText>{question.helpText}</FormHelperText>
            <FormErrorMessage textAlign={"left"}>
              {formik.errors[question.name]}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      );
    case "bookingTimeSelector":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          <BookingTimeSelector question={question} formik={formik} />
        </GridItem>
      );
    case "fileInput":
      return (
        <GridItem
          {...question.gridParams}
          display={
            question.displayDependantOn
              ? [
                  formik.values[question.displayDependantOn]
                    ? "visable"
                    : "none",
                ]
              : "visable"
          }
        >
          <FileInput question={question} formik={formik} />
        </GridItem>
      );
    case "textOnly":
      return (
        <GridItem
          {...question.gridParams}
          display={conditionallyDisplay(question)}
        >
          {question.text}
        </GridItem>
      );
    default:
      return <></>;
  }
};

export default Question;
