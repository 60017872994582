import React, { useMemo, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  IconButton,
  Text,
  HStack,
  chakra,
  Box,
  Stack,
  Flex,
  Tooltip,
  Select,
} from "@chakra-ui/react";

import "react-loading-skeleton/dist/skeleton.css";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import { FaSearch } from "react-icons/fa";
import KPIDailyModal from "./KPIDailyModal";
import { connect } from "react-redux";
import TableSkeleton from "./TableSkeleton";

const KPITable = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(false);

  useEffect(() => {
    setGlobalFilter(props.searchValue || undefined);
  }, [props.searchValue]);

  const onModalClose = () => setModalIsOpen(false);

  const columns = useMemo(
    () => [
      {
        Header: "Clinician",
        accessor: "name",
        Cell: (original) => (
          <>
            <Text fontSize="md" letterSpacing={0.8} fontWeight="bold">
              {original.cell.row.original.name}
            </Text>
            <Text fontSize="sm" color="gray.500">
              Speech Pathologist
            </Text>
          </>
        ),
      },

      {
        id: "daily_kpi",
        Header: "Goal",
        accessor: (d) => (d.daily_kpi * 5).toFixed(2),
        Cell: (original) => (
          <Text fontWeight="normal" fontSize="sm">
            <span fontWeight="semibold">
              {(original.cell.row.original.daily_kpi * 5).toFixed(2)}
            </span>{" "}
            Hours
          </Text>
        ),
      },
      {
        id: "scheduled_hours",
        Header: "Scheduled",
        accessor: (d) =>
          Object.values(d.appointments)
            .reduce((a, b) => a + b)
            .toFixed(2),
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {Object.values(original.cell.row.original.appointments)
              .reduce((a, b) => a + b)
              .toFixed(2)}{" "}
            Hours
          </Text>
        ),
      },
      {
        id: "logistics_personnel",
        Header: "Logistics Personnel",
        accessor: "logistics_personnel_name",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {original.cell.row.original.logistics_personnel_name}
          </Text>
        ),
      },
      {
        id: "status",
        Header: "Status",
        accessor: (d) => (d.status === true ? "Good" : "Under"),
        Cell: (original) => (
          <Badge
            colorScheme={original.cell.row.original.status ? "green" : "red"}
            rounded="full"
            border="2px black solid"
            px={2}
          >
            {original.cell.row.original.status ? "Good" : "Under"}
          </Badge>
        ),
      },
      {
        Header: "Action",
        Cell: (original) => (
          <HStack>
            <IconButton
              variant="grpButton"
              color="gray.700"
              aria-label="View Clinician Weekly Hours"
              icon={<FaSearch />}
              onClick={() => {
                setSelectedClinician(original.cell.row.original);
                setModalIsOpen(true);
              }}
            />
          </HStack>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: props.appointments,
      initialState: {
        sortBy: [
          {
            id: "status",
            desc: false,
          },
        ],
        hiddenColumns: [!props.user.is_staff ? "logistics_personnel" : ""],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Box mt={3} rounded="xl" overflowX={{ base: "scroll", md: "hidden" }}>
        {props.appointments.length <= 0
          ? [<TableSkeleton />]
          : [
              <>
                <Table variant="grpTable" {...getTableProps()}>
                  <Thead>
                    {headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <Th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <chakra.span pl="4">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </chakra.span>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <Td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </>,
            ]}
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        textColor="white"
        mt={3}
      >
        <Flex gap={2}>
          <Tooltip label="First Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              variant="grpButton"
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex
          alignItems="center"
          bg="pink.100"
          paddingX={5}
          paddingY={1}
          rounded="xl"
          border="2px solid black"
          textColor="black"
          gap={3}
        >
          <Text flexShrink="0" mr={1}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>

          <Select
            display={{ base: "none", xl: "inherit" }}
            w={32}
            borderColor="black"
            borderWidth="2px"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex gap={2}>
          <Tooltip label="Next Page">
            <IconButton
              variant="grpButton"
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <KPIDailyModal
        isOpen={modalIsOpen}
        onClose={onModalClose}
        selectedClinician={selectedClinician}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  appointments: state.system.appointments,
  clinicians: state.system.clinicians,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(KPITable);
