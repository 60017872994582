import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
  Heading,
  FormErrorMessage,
  HStack,
  Icon,
  FormControl,
  FormLabel,
  Divider,
  FormHelperText,
} from "@chakra-ui/react";
import { bookingTimeOptions } from "../Variables/questions";
import { BsSunrise, BsSun, BsMoon } from "react-icons/bs";
// import moment from "moment";
import moment from "moment-timezone"

const BookingTimeSelector = ({ question, formik }) => {
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [selectedDayTimes, setSelectedDayTimes] = useState([]);

  useEffect(() => {
    if (formik.values[question.name].length > 0) {
      setSelectedDayTimes([...formik.values[question.name]]);
    }
  }, [formik.values[question.name]]);

  const handleTimeClick = (time) => {
    if (selectedDayTimes.includes(`${selectedDay} ${time}`)) {
      setSelectedDayTimes(
        selectedDayTimes.filter((item) => item !== `${selectedDay} ${time}`)
      );
      formik.setFieldValue(
        question.name,
        selectedDayTimes.filter((item) => item !== `${selectedDay} ${time}`)
      );
    } else {
      setSelectedDayTimes([...selectedDayTimes, `${selectedDay} ${time}`]);
      formik.setFieldValue(question.name, [
        ...selectedDayTimes,
        `${selectedDay} ${time}`,
      ]);
    }
  };

  const timeOfDays = [
    {
      name: "Morning",
      icon: BsSunrise,
    },
    {
      name: "Afternoon",
      icon: BsSun,
    },
    {
      name: "Evening",
      icon: BsMoon,
    },
  ];

  return (
    <FormControl
      id={question.name}
      name={question.name}
      isInvalid={formik.errors[question.name] && formik.submitCount > 0}
      maxW={{ base: "70vw", md: "100%" }}
    >
      <Box pb={3}>
        <FormLabel>{question.question}</FormLabel>
        <Grid
          templateColumns={"repeat(6, 1fr)"}
          gap={3}
          my={4}
          overflow={{ base: "scroll", sm: "auto" }}
        >
          {bookingTimeOptions.map((day) => (
            <GridItem key={day.day}>
              <Box
                p={4}
                bg={selectedDay === day.day ? "facebook.500" : "white"}
                textColor={selectedDay === day.day ? "white" : "black"}
                borderRadius="xl"
                border="1px solid rgba(0,0,0,0.1)"
                cursor={"pointer"}
                _hover={selectedDay !== day.day && { bg: "gray.100" }}
                onClick={() => setSelectedDay(day.day)}
              >
                <Text textAlign={"center"}>{day.day}</Text>
                {selectedDayTimes.some((substring) =>
                  substring.includes(day.day)
                ) && (
                  <Text textAlign={"center"}>
                    {`${
                      selectedDayTimes.filter((substring) =>
                        substring.includes(day.day)
                      ).length
                    } selected`}
                  </Text>
                )}
              </Box>
            </GridItem>
          ))}
        </Grid>
        <Divider my={3} display={{ base: "inherit", md: "none" }} />
        <Grid
          templateColumns={"repeat(3,1fr)"}
          display={{ base: "none", md: "grid" }}
          my={3}
        >
          {timeOfDays.map((item) => (
            <GridItem key={item.name}>
              <HStack alignContent={"center"}>
                <Heading fontSize={"2xl"} fontWeight="normal">
                  {item.name}
                </Heading>
                <Icon as={item.icon} w={6} h={6} />
              </HStack>
            </GridItem>
          ))}
        </Grid>
        <Grid
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(3,1fr)" }}
          templateRows={{ base: "repeat(5,1fr)", md: "repeat(2,1fr)" }}
          gridAutoFlow="column"
          gap={3}
        >
          {bookingTimeOptions
            .find((day) => day.day === selectedDay)
            .timeslots.map((time) => {
              return (
                <GridItem key={`${selectedDay} ${time}`}>
                  <Box
                    border={
                      formik.errors[question.name] && formik.submitCount > 0
                        ? [
                            selectedDayTimes.includes(`${selectedDay} ${time}`)
                              ? "1px solid rgba(0,0,0,0.1)"
                              : "2px solid #E53E3E",
                          ]
                        : "1px solid rgba(0,0,0,0.1)"
                    }
                    rounded={"xl"}
                    onClick={() => handleTimeClick(time)}
                    p={3}
                    cursor={"pointer"}
                    _hover={
                      !selectedDayTimes.includes(`${selectedDay} ${time}`) && {
                        bg: "gray.100",
                      }
                    }
                    {...(selectedDayTimes.includes(
                      `${selectedDay} ${time}`
                    ) && {
                      bg: "facebook.400",
                      textColor: "white",
                    })}
                  >
                    <Text fontWeight={"normal"}>{time} {moment.tz('Australia/Melbourne').format('z')}</Text>
                  </Box>
                </GridItem>
              );
            })}
        </Grid>
        <FormHelperText>{question.helpText}</FormHelperText>
        <FormErrorMessage>{formik.errors[question.name]}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};

export default BookingTimeSelector;
