import { useState, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import Header from "./components/Header";
import NewUserDrawer from "./components/NewUserDrawer";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import theme from "./components/Theme";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Outlet } from "react-router";
import Login from "./components/Login";
import StaffRoute from "./components/StaffRoute";
import AdminRoute from "./components/AdminRoute";

import { loadUser } from "./actions/auth";
import Clinicians from "./components/Clinicians";
import Users from "./components/Users";
import NotFound from "./components/NotFound";
import ApiKeyInput from "./components/ApiKeyInput";
import ChangePassword from "./components/ChangePassword";
import KPIs from "./components/KPIs";
import ReferralForm from "./components/ReferralForm/ReferralForm";
import VantaBackground from "./components/VantaBackground";

import grpBackground from "./images/GRP_background.svg";

const App = () => {
  const [userDrawerIsOpen, setUserDrawerIsOpen] = useState(false);

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              element={
                <>
                  <VantaBackground />
                  <Header />
                  <Outlet />
                </>
              }
            >
              <Route exact path="/" element={<Login />} />
              <Route element={<StaffRoute />}>
                <Route exact path="/kpis" element={<KPIs />} />
                <Route exact path="/api_key" element={<ApiKeyInput />} />
                <Route
                  exact
                  path="/change_password"
                  element={<ChangePassword />}
                />
              </Route>
              <Route element={<AdminRoute />}>
                <Route exact path="/clinicians" element={<Clinicians />} />
                <Route exact path="/users" element={<Users />} />
              </Route>
            </Route>

            <Route
              exact
              path="/referral"
              element={
                <Box
                  backgroundImage={grpBackground}
                  minH="100vh"
                  minW="100vw"
                  backgroundColor={"pink.50"}
                >
                  <Header />
                  <ReferralForm />
                </Box>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <VantaBackground />
                  <Header />
                  <NotFound />
                </>
              }
            />
          </Routes>

          <NewUserDrawer
            userDrawerIsOpen={userDrawerIsOpen}
            userDrawerOnClose={() => setUserDrawerIsOpen(false)}
          />
        </Router>
      </Provider>
    </ChakraProvider>
  );
};

export default App;
