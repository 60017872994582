import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { connect } from "react-redux";
import {
  Container,
  HStack,
  Spacer,
  Heading,
  Box,
  Input,
} from "@chakra-ui/react";
import { getClinicians, clearClinicians } from "../actions/system";
import ClinicianTable from "./ClinicianTable";
import CliniciansModal from "./CliniciansModal";

const Clinicians = (props) => {
  const [clinicianModalOpen, setClinicianModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedClinician, setSelectedClinician] = useState({
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    props.clearClinicians();
    props.getClinicians();
  }, []);

  const handleModalOpen = (clinicianId) => {
    setSelectedClinician(clinicianId);
    setClinicianModalOpen(true);
  };

  const handleModalClose = () => {
    setClinicianModalOpen(false);
  };

  return (
    <>
      <Container maxW="container.xl">
        <Box mt={6}>
          <HStack mb={6}>
            <Heading fontSize="5xl" fontWeight={"extrabold"} textColor="white">
              Clinicians
            </Heading>
            <Spacer />
            <Box>
              <Input
                variant="grpPinkInput"
                type="text"
                placeholder="Find Clinician..."
                _placeholder={{ color: "#020" }}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "Search...")}
                w={"150px"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Box>
          </HStack>

          <ClinicianTable
            handleModalOpen={handleModalOpen}
            searchValue={searchValue}
          />
        </Box>
      </Container>
      <CliniciansModal
        isOpen={clinicianModalOpen}
        onClose={handleModalClose}
        selectedClinician={selectedClinician}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.system.clinicians,
});

export default connect(mapStateToProps, { getClinicians, clearClinicians })(
  Clinicians
);
