import {
  GET_CLINICIANS,
  GET_USERS,
  ADD_USER,
  LINK_CLINICIAN,
  UPDATE_CLINICIAN,
  GET_APPOINTMENTS,
  CLEAR_APPOINTMENTS,
  LOGOUT_SUCCESS,
  CLEAR_CLINICIANS,
  CLEAR_USERS,
  UPDATE_USER,
} from "../actions/types";

var _ = require("lodash");

const initialState = {
  appointments: [],
  clinicians: [],
  users: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLINICIANS:
      return {
        ...state,
        clinicians: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };

    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
      };

    case LINK_CLINICIAN:
    case UPDATE_CLINICIAN:
      console.error(action.payload);
      return {
        ...state,
        clinicians: state.clinicians.map((clinician) =>
          clinician.cliniko_id === action.payload.cliniko_id
            ? { ...clinician, ...action.payload, linked: true }
            : clinician
        ),
      };
    case CLEAR_CLINICIANS:
      return {
        ...state,
        clinicians: [],
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case CLEAR_APPOINTMENTS:
      return {
        ...state,
        appointments: [],
      };
    case LOGOUT_SUCCESS:
      return {
        appointments: [],
        clinicians: [],
        users: [],
      };

    default:
      return state;
  }
}
