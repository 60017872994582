import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsers, linkClinician, updateClinican } from "../actions/system";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

const clinicianSchema = Yup.object().shape({
  logistics_personnel: Yup.string().required(
    "Please select logistics personnel"
  ),
  daily_kpi: Yup.number()
    .min(0, "Daily KPI must be greater than 0")
    .required("Please enter clinicians KPI"),
});

const CliniciansModal = (props) => {
  const [selectedClinician, setSelectedClinician] = useState({
    first_name: "",
    last_name: "",
    managing_user: "",
    daily_kpi: 0,
    cliniko_id: 0,
  });

  useEffect(() => {
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedClinician(
        props.clinicians.find((c) => c.cliniko_id === props.selectedClinician)
      );
    } else {
      setSelectedClinician({
        first_name: "",
        last_name: "",
        managing_user: "",
        daily_kpi: 0,
        cliniko_id: 0,
        linked: false,
      });
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (selectedClinician.logistics_personnel) {
      formik.setFieldValue(
        "logistics_personnel",
        selectedClinician.logistics_personnel
      );
      formik.setFieldValue("daily_kpi", selectedClinician.daily_kpi);
    }
  }, [selectedClinician.managing_user]);

  const handleModalClose = () => {
    formik.resetForm();
    props.onClose();
  };

  const formik = useFormik({
    initialValues: {
      logistics_personnel: "",
      daily_kpi: 0,
    },
    onSubmit: (values) => {
      selectedClinician.linked
        ? props.updateClinican(
            { ...selectedClinician, ...values },
            handleModalClose
          )
        : props.linkClinician(
            { ...selectedClinician, ...values },
            handleModalClose
          );
    },
    validationSchema: clinicianSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <Modal isOpen={props.isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{selectedClinician.name}</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl
                    id="logistics_personnel"
                    name="logistics_personnel"
                    isInvalid={
                      formik.errors.logistics_personnel &&
                      formik.touched.logistics_personnel
                    }
                  >
                    <FormLabel htmlFor="logistics_personnel">
                      Logistics Personnel
                    </FormLabel>
                    <Select
                      borderColor="black"
                      borderWidth="2px"
                      _hover={{ borderColor: "black" }}
                      value={formik.values.logistics_personnel}
                      onChange={formik.handleChange}
                    >
                      <option selected defaultValue value="">
                        ---------
                      </option>
                      {props.users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {formik.errors.logistics_personnel}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="daily_kpi"
                    name="daily_kpi"
                    isInvalid={
                      formik.errors.daily_kpi && formik.touched.daily_kpi
                    }
                  >
                    <FormLabel htmlFor="daily_kpi">Daily KPI</FormLabel>
                    <NumberInput
                      defaultValue={formik.values.daily_kpi}
                      precision={3}
                      step={0.5}
                      size="sm"
                      name="kpi"
                      value={formik.values.daily_kpi}
                      onChange={(e) => formik.setFieldValue("daily_kpi", e)}
                    >
                      <NumberInputField
                        rounded="md"
                        borderColor="black"
                        borderWidth="2px"
                        _hover={{ borderColor: "black" }}
                      />
                      <NumberInputStepper borderColor="black">
                        <NumberIncrementStepper />
                        <NumberDecrementStepper borderColor="black" />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {formik.errors.daily_kpi}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="white"
                color="black"
                mr={3}
                fontWeight="normal"
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button
                variant={"grpButton"}
                fontWeight="normal"
                type="submit"
                textColor="black"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.system.clinicians,
  users: state.system.users,
});

export default connect(mapStateToProps, {
  getUsers,
  linkClinician,
  updateClinican,
})(CliniciansModal);
