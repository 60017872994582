import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Flex,
  Text,
  Badge,
  IconButton,
  chakra,
  Tooltip,
  Select,
  Stack,
} from "@chakra-ui/react";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import { FiLink, FiEdit } from "react-icons/fi";
import TableSkeleton from "./TableSkeleton";

const CliniciansTable = (props) => {
  useEffect(() => {
    setGlobalFilter(props.searchValue || undefined);
  }, [props.searchValue]);

  const columns = useMemo(
    () => [
      {
        Header: "Clinican",
        accessor: "name",
        Cell: (original) => (
          <>
            <Text fontSize="md" letterSpacing={0.8} fontWeight="semibold">
              {original.cell.row.original.name}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {original.cell.row.original.designation}
            </Text>
          </>
        ),
      },

      {
        Header: "Daily KPI",
        accessor: "daily_kpi",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {original.cell.row.original.daily_kpi >= 0
              ? [
                  `${parseFloat(original.cell.row.original.daily_kpi).toFixed(
                    2
                  )} Hours`,
                ]
              : "Not Linked"}
          </Text>
        ),
      },
      {
        Header: "Linked User",
        accessor: "logistics_personnel",
        Cell: (original) => (
          <Text fontWeight="medium" fontSize="sm">
            {original.cell.row.original.logistics_personnel_name
              ? original.cell.row.original.logistics_personnel_name
              : "Not Linked"}
          </Text>
        ),
      },
      {
        Header: "Linked Status",
        accessor: "status",
        Cell: (original) => (
          <Badge
            colorScheme={
              original.cell.row.original.linked === true ? "green" : "red"
            }
            rounded="full"
            border="2px black solid"
            px={2}
          >
            {original.cell.row.original.linked === true ? "Linked" : "Unlinked"}
          </Badge>
        ),
      },
      {
        Header: "Action",
        Cell: (original) => (
          <IconButton
            variant="grpButton"
            aria-label="Link to User"
            icon={
              original.cell.row.original.linked === false ? (
                <FiLink color="black" />
              ) : (
                <FiEdit color="black" />
              )
            }
            onClick={() => {
              props.handleModalOpen(original.cell.row.original.cliniko_id);
            }}
          />
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    pageOptions,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: props.clinicians,
      initialState: {},
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Box mt={3} rounded="xl" overflowX={{ base: "scroll", md: "hidden" }}>
        {props.clinicians.length <= 0
          ? [<TableSkeleton />]
          : [
              <Table variant="grpTable" {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <chakra.span pl="4">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              )
                            ) : null}
                          </chakra.span>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>,
            ]}
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        textColor="white"
        mt={3}
      >
        <Flex gap={2}>
          <Tooltip label="First Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              variant="grpButton"
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex
          alignItems="center"
          bg="pink.100"
          paddingX={5}
          paddingY={1}
          rounded="xl"
          border="2px solid black"
          textColor="black"
          gap={3}
        >
          <Text flexShrink="0" mr={1}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>

          <Select
            display={{ base: "none", xl: "inherit" }}
            w={32}
            borderColor="black"
            borderWidth="2px"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex gap={2}>
          <Tooltip label="Next Page">
            <IconButton
              variant="grpButton"
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              variant="grpButton"
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.system.clinicians,
});

export default connect(mapStateToProps, {})(CliniciansTable);
