export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_CLINICIANS = "GET_CLINICIANS";
export const LINK_CLINICIAN = "LINK_CLINICIAN";
export const UPDATE_CLINICIAN = "UPDATE_CLINICIAN";
export const CLEAR_CLINICIANS = "CLEAR_CLINICIANS";

export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const UPDATE_USER = "UPDATE_USER";

export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const CLEAR_APPOINTMENTS = "CLEAR_APPOINTMENTS";

export const API_KEY_CHANGE = "API_KEY_CHANGE";

export const ADDING_REFERRAL = "ADDING_REFERRAL";
