import React, { useState } from "react";
import { connect } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Select,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";

import { MdCheckCircle, MdSettings } from "react-icons/md";

import { HiEye, HiEyeOff } from "react-icons/hi";

import { addUser } from "../actions/system";

const newUserSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter user's first name"),
  last_name: Yup.string().required("Please enter user's last name"),
  email: Yup.string()
    .email("Email is not valid")
    .required("Please enter user's email"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})/,
      "Must Contain 8-64 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .required("Please enter a password"),
  is_staff: Yup.bool().required(),
});

const NewUserDrawer = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      is_staff: false,
    },
    onSubmit: () => {
      props.addUser(formik.values, onClose);
    },
    validationSchema: newUserSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  const onClose = () => {
    formik.handleReset();
    props.userDrawerOnClose();
  };

  return (
    <>
      <Drawer
        isOpen={props.userDrawerIsOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent overflowY="scroll" border={"2px solid black"}>
          <DrawerCloseButton />
          <DrawerHeader>Create a New User</DrawerHeader>

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <DrawerBody>
                <Box my={4}>
                  <Stack spacing={4}>
                    <FormControl
                      id="first_name"
                      name="first_name"
                      isInvalid={
                        formik.errors.first_name && formik.touched.first_name
                      }
                    >
                      <FormLabel>First Name</FormLabel>
                      <Input
                        variant={"grpInput"}
                        bg="white"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>
                        {formik.errors.first_name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="last_name"
                      name="last_name"
                      isInvalid={
                        formik.errors.last_name && formik.touched.last_name
                      }
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        variant={"grpInput"}
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>
                        {formik.errors.last_name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="email"
                      name="email"
                      isInvalid={formik.errors.email && formik.touched.email}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Input
                        variant={"grpInput"}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="password"
                      name="password"
                      isInvalid={
                        formik.errors.password && formik.touched.password
                      }
                    >
                      <FormLabel>Password</FormLabel>
                      <InputGroup>
                        <Input
                          variant={"grpInput"}
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        <InputRightElement h={"full"}>
                          <Button
                            p={3}
                            variant={"ghost"}
                            onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                            }
                          >
                            {showPassword ? <HiEye /> : <HiEyeOff />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {formik.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="is_staff">User Type</FormLabel>

                      <Select
                        variant={"grpSelect"}
                        id="is_staff"
                        name="is_staff"
                        value={formik.values.is_staff}
                        onChange={formik.handleChange}
                      >
                        <option value={false}>Normal User</option>
                        <option value={true}>Administrator</option>
                      </Select>
                      {formik.values.is_staff === "true" ? (
                        <>
                          <Text my={3}>
                            By selecting this user to be an Administrator, you
                            will allow them to perform the following functions:
                          </Text>
                          <List
                            spacing={3}
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="normal"
                            ms={3}
                          >
                            <ListItem>
                              <ListIcon as={MdCheckCircle} color="teal" />
                              View and Assign Clinicans to Logistics Personnel
                            </ListItem>
                            <ListItem>
                              <ListIcon as={MdCheckCircle} color="teal" />
                              Add and Edit Clinician KPI's
                            </ListItem>

                            <ListItem>
                              <ListIcon as={MdSettings} color="teal" />
                              Add new users to the platform, both Normal Users
                              and Administrators
                            </ListItem>
                          </List>
                        </>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Stack>
                </Box>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={onClose}
                  fontWeight="light"
                >
                  Cancel
                </Button>
                <Button
                  variant={"grpButton"}
                  fontWeight="light"
                  type="submit"
                  _hover={{ bg: "pink.200" }}
                >
                  Save
                </Button>
              </DrawerFooter>
            </form>
          </FormikProvider>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addUser })(NewUserDrawer);
