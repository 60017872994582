import React from "react";

import {
  FormControl,
  FormLabel,
  Flex,
  Stack,
  Icon,
  Input,
  chakra,
  VisuallyHidden,
  Text,
  HStack,
  Button,
  Spacer,
  FormHelperText,
} from "@chakra-ui/react";
import { toastAlert } from "../../ToastAlerts";

const acceptedFileExtensions = ['pdf','doc', 'docx', 'jpeg', 'jpg', 'png']

const FileInput = ({ question, formik }) => {
  const handleChange = (event) => {
    event.preventDefault();
    const file = event.currentTarget.files[0];

    if(formik.values[question.name].map((item) => item.name).includes(file.name)) {
      return toastAlert("error", "File already added");
    }
    if(file.size > 5242880){
      return toastAlert("error", "File is too large, please send to GRP directly");
    }
    const file_type = file.name.split(".").slice(-1).pop().toLowerCase()
    if(!acceptedFileExtensions.includes(file_type)){
      return toastAlert("error", "File type not allowed, please send to GRP directly");
    }
    if(formik.values[question.name].length === 2){
      return toastAlert("error", "You cannot upload more than 2 files, please send remainder to GRP directly");
    }
    formik.setFieldValue(
      question.name,
      formik.values[question.name].concat(event.currentTarget.files[0])
    );
  };

  const handleRemove = (file) => {
    formik.setFieldValue(
      question.name,
      formik.values[question.name].filter((item) => item.name !== file.name)
    );
  };

  return (
    <FormControl
      id={question.name}
      name={question.name}
      isInvalid={formik.errors[question.name] && formik.submitCount > 0}
    >
      <FormLabel>{question.question}</FormLabel>
      <Flex
        mt={1}
        justify="center"
        px={6}
        pt={5}
        pb={6}
        borderWidth={2}
        borderColor={"gray.300"}
        borderStyle="dashed"
        rounded="md"
      >
        <Stack spacing={1} textAlign="center">
          <Icon
            mx="auto"
            boxSize={12}
            color={"gray.400"}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Icon>
          <Flex
            fontSize="sm"
            color={"gray.600"}
            alignItems="baseline"
            justifyContent={"center"}
          >
            <chakra.label
              htmlFor={question.name}
              cursor="pointer"
              rounded="md"
              fontSize="md"
              color={"facebook.700"}
              pos="relative"
              _hover={{
                color: "brand.400",
              }}
              textAlign="center"
            >
              <span>Click here to upload a file</span>
              <VisuallyHidden>
                <Input
                  id={question.name}
                  name={question.name}
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                  onChange={(e) => handleChange(e)}
                />
              </VisuallyHidden>
            </chakra.label>
          </Flex>
          <Text fontSize="md" color={"gray.500"}>
            We accept PDF, Word Documents, JPEG & PNG files
          </Text>
          {formik.values[question.name].map((file) => (
            <HStack alignContent={"center"} key={file.name}>
              <Text>{file.name}</Text>
              <Spacer />
              <Button
                size={{ base: "sm", md: "xs" }}
                fontSize="sm"
                p={1}
                colorScheme="red"
                onClick={() => handleRemove(file)}
              >
                Remove
              </Button>
            </HStack>
          ))}
        </Stack>
      </Flex>
      <FormHelperText>{question.helpText}</FormHelperText>
    </FormControl>
  );
};

export default FileInput;
