import React, { forwardRef, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import _ from "lodash";

import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Select,
  useTheme,
  css as chakraCSS,
  HStack,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { ClassNames } from "@emotion/react";
import moment from "moment";

const CustomInput = forwardRef((props, ref) => {
  return (
    <InputGroup>
      <Input {...props} ref={ref} />
      <InputRightElement
        userSelect="none"
        pointerEvents="none"
        zIndex={"0"}
        children={<CalendarIcon />}
      />
    </InputGroup>
  );
});

const DOBCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = _.range(
    parseInt(moment().subtract(115, "year").format("YYYY")),
    parseInt(moment().add(1, "year").format("YYYY"))
  );

  return (
    <Stack
      pb={1}
      isInline
      justify={"space-between"}
      textAlign="left"
      pl={4}
      pr={2}
    >
      <HStack gap={0}>
        <Text flex={1} fontSize="sm" fontWeight="medium" color="facebook.800">
          {new Intl.DateTimeFormat("en-AU", {
            month: "long",
          }).format(date)}
        </Text>
        <Select
          rounded={"full"}
          size={"xs"}
          maxH="100px"
          fontSize="sm"
          fontWeight={"medium"}
          color="facebook.800"
          value={date.getFullYear()}
          onChange={({ target: { value } }) =>
            changeYear(moment(value).format("YYYY"))
          }
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </HStack>
      <HStack>
        <IconButton
          borderRadius="full"
          size="sm"
          variant="ghost"
          aria-label="Previous Month"
          icon={<ChevronLeftIcon fontSize="14px" />}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />
        <IconButton
          borderRadius="full"
          size="sm"
          variant="ghost"
          aria-label="Next Month"
          icon={<ChevronRightIcon fontSize="14px" />}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </HStack>
    </Stack>
  );
};

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = _.range(
    parseInt(moment().subtract(10, "year").format("YYYY")),
    parseInt(moment().add(10, "year").format("YYYY"))
  );

  return (
    <Stack
      pb={1}
      isInline
      justify={"space-between"}
      textAlign="left"
      pl={4}
      pr={2}
    >
      <HStack gap={0}>
        <Text flex={1} fontSize="sm" fontWeight="medium" color="facebook.800">
          {new Intl.DateTimeFormat("en-AU", {
            month: "long",
          }).format(date)}
        </Text>
        <Select
          rounded={"full"}
          size={"xs"}
          maxH="100px"
          fontSize="sm"
          fontWeight={"medium"}
          color="facebook.800"
          value={date.getFullYear()}
          onChange={({ target: { value } }) =>
            changeYear(moment(value).format("YYYY"))
          }
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </HStack>
      <HStack>
        <IconButton
          borderRadius="full"
          size="sm"
          variant="ghost"
          aria-label="Previous Month"
          icon={<ChevronLeftIcon fontSize="14px" />}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />
        <IconButton
          borderRadius="full"
          size="sm"
          variant="ghost"
          aria-label="Next Month"
          icon={<ChevronRightIcon fontSize="14px" />}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </HStack>
    </Stack>
  );
};

function useDatePickerStyles() {
  const theme = useTheme();
  return useMemo(() => {
    const defaultStyles = {
      p: 2,
      bg: "white",
      border: "1px solid",
      borderColor: "gray.200",
      boxShadow: "sm",
      "& .react-datepicker": {
        "&__header": {
          bg: "none",
          borderBottom: "none",
        },
        "&__month": {
          mt: 0,
          color: "gray.200",
        },
        "&__day-name": {
          color: "gray.400",
          fontWeight: "medium",
          w: 7,
        },
        "&__day": {
          lineHeight: "28px",
          color: "grey.500",
          w: 7,
          h: 7,
          borderRadius: "full",
        },
        "&__day:not(.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected):hover":
          {
            bg: "white",
            boxShadow: "0 0 1px 1px rgba(0,0,0,0.2)",
          },
        "&__day--today": {
          bg: "grey.100",
          fontWeight: "400",
        },
        "&__day--selected, &__day--keyboard-selected": {
          bg: "facebook.400",
          color: "white",
        },
      },
    };
    return chakraCSS(defaultStyles)(theme);
  }, [theme]);
}

export const DatePicker = ({ question, formik }) => {
  const styles = useDatePickerStyles();

  const render = ({ css }) => {
    return (
      <ReactDatePicker
        name={question.name}
        dateFormat="dd/MM/yyyy"
        showPopperArrow={false}
        popperClassName={css({ marginTop: "4px!important" })}
        calendarClassName={css(styles)}
        selected={formik.values[question.name]}
        onChange={(e) =>
          e === null
            ? formik.setFieldValue(question.name, "")
            : formik.setFieldValue(question.name, e)
        }
        customInput={<CustomInput />}
        renderCustomHeader={question.DOB ? DOBCustomHeader : CustomHeader}
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
      />
    );
  };

  return <ClassNames>{render}</ClassNames>;
};
