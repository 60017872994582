import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  Stack,
  Button,
  Grid,
  Flex,
  Box,
  SimpleGrid,
  HStack,
  Text,
} from "@chakra-ui/react";

import Question from "./Question";

const FormBody = ({
  referralData,
  validationSchema,
  buttonChildren,
  initialValues,
  questions,
  onSubmit,
  name,
}) => {
  useEffect(() => {
    Object.keys(initialValues).forEach((key) => {
      if (referralData[key] !== undefined) {
        initialValues[key] = referralData[key];
      }
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values) => {
      onSubmit(name, values);
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Stack direction="column" spacing={4}>
            <Grid
              gap={4}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(12, 1fr)",
              }}
            >
              {questions.map((question) => (
                <Question
                  question={question}
                  formik={formik}
                  key={question.name}
                />
              ))}
            </Grid>
            {buttonChildren}

            <Button w="100%" type="submit" colorScheme={"facebook"}>
              Next
            </Button>
            {!buttonChildren && (
              <Text textAlign={"center"} mt={0} color="gray.600">
                To go back to a previous page, click the blue check mark at the
                top of the page.
              </Text>
            )}
          </Stack>
        </form>
      </FormikProvider>
    </>
  );
};

const FormPage = ({
  steps,
  referralData,
  questions,
  buttonChildren,
  onSubmit,
  name,
}) => {
  const schemaObj = {};
  const initialValues = {};

  const determineInitialValues = (type) => {
    switch (type) {
      case "text":
      case "ausPhoneNumber":
        return "";
      case "fileInput":
      case "bookingTimeSelector":
        return [];
      case "linkedCheckbox":
        return {
          viewed: false,
          accepted: false,
        };
      default:
        return undefined;
    }
  };

  questions
    .filter((q) => q.type !== "textOnly")
    .forEach((q) => (schemaObj[q.name] = q.yup));

  questions
    .filter((q) => q.type !== "textOnly")
    .forEach((q) => (initialValues[q.name] = determineInitialValues(q.type)));

  const validationSchema = Yup.object().shape(schemaObj);

  return (
    <FormBody
      step={steps}
      referralData={referralData}
      validationSchema={validationSchema}
      initialValues={initialValues}
      questions={questions}
      buttonChildren={buttonChildren}
      onSubmit={onSubmit}
      name={name}
    />
  );
};

export default FormPage;
