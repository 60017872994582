import React, { useState, useEffect } from "react";
import { getAppointments, clearAppointments } from "../actions/system";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Heading,
  HStack,
  Badge,
  Spacer,
  IconButton,
  VStack,
  Input,
} from "@chakra-ui/react";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import moment from "moment";
import KPITable from "./KPITable";

const KPIs = (props) => {
  const [week, setWeek] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [currentWeek, setCurrentWeek] = useState(
    parseInt(moment().add(week, "week").format("w"))
  );

  useEffect(() => {
    props.clearAppointments();
    props.getAppointments(week);
    setSearchValue("");
    setCurrentWeek(parseInt(moment().add(week, "week").format("w")));
  }, [week]);

  return (
    <Container maxW="container.xl">
      <Box mt={6} style={{ zIndex: "5" }}>
        <HStack>
          <VStack spacing={1} align="start">
            <Heading fontSize="5xl" fontWeight={"extrabold"} textColor="white">
              Week {currentWeek}
            </Heading>
            {parseInt(moment().format("w")) === currentWeek ? (
              <Badge
                colorScheme="blue"
                my="auto"
                rounded="full"
                fontSize="0.8em"
                px={3}
                border="2px solid black"
              >
                This Week
              </Badge>
            ) : (
              [
                parseInt(moment().add(1, "week").format("w")) ===
                currentWeek ? (
                  <Badge
                    colorScheme="purple"
                    my="auto"
                    rounded="full"
                    fontSize="0.8em"
                    px={2}
                    border="2px solid black"
                  >
                    Next Week
                  </Badge>
                ) : (
                  <Badge
                    colorScheme="orange"
                    my="auto"
                    rounded="full"
                    fontSize="0.8em"
                    px={2}
                    border="2px solid black"
                  >
                    {Math.abs(week)} Weeks{" "}
                    {Math.sign(week) === -1 ? "Ago" : "Away"}
                  </Badge>
                ),
              ]
            )}
          </VStack>
          <Spacer />
          <HStack>
            <Box>
              <Input
                variant="grpPinkInput"
                type="text"
                placeholder="Search..."
                _placeholder={{ color: "#020" }}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "Search...")}
                w={"150px"}
                disabled={props.appointments.length === 0}
                roundedEnd="none"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Box>
            <IconButton
              variant="grpButton"
              onClick={() => setWeek(week - 1)}
              disabled={props.appointments.length === 0}
              icon={<ChevronLeftIcon w={8} h={8} />}
            />
            <IconButton
              variant="grpButton"
              disabled={props.appointments.length === 0}
              onClick={() => setWeek(week + 1)}
              icon={<ChevronRightIcon w={8} h={8} />}
            />
          </HStack>
        </HStack>

        <KPITable currentWeek={currentWeek} searchValue={searchValue} />
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  appointments: state.system.appointments,
});

export default connect(mapStateToProps, {
  getAppointments,
  clearAppointments,
})(KPIs);
