import React from "react";
import {
  HStack,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  VStack,
  Button,
  Spacer,
  Image,
  Container,
  Box,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Avatar,
} from "@chakra-ui/react";
import { useViewportScroll } from "framer-motion";
import {
  AiFillHome,
  AiOutlineMenu,
  AiFillSignal,
  AiOutlineUser,
} from "react-icons/ai";
import { RiUserUnfollowFill } from "react-icons/ri";
import { FaUserFriends, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import grpLogo from "../images/grpaltlogo.svg";
import { logout } from "../actions/auth";

const Header = (props) => {
  const bg = useColorModeValue("white", "gray.800");

  const [y, setY] = React.useState(0);

  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);
  const cl = useColorModeValue("gray.800", "white");
  const mobileNav = useDisclosure();

  const links = props.auth.isAuthenticated
    ? [
        {
          name: "KPIs",
          link: "/kpis",
          icon: <AiFillSignal />,
        },
      ]
    : [];
  if (props.auth.isAuthenticated && props.auth.user.is_staff) {
    links.push(
      {
        name: "Clinicians",
        link: "/clinicians",
        icon: <FaUsers />,
      },
      {
        name: "Users",
        link: "/users",
        icon: <FaUserFriends />,
      }
    );
  }

  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? "flex" : "none"}
      flexDirection="column"
      p={2}
      pb={4}
      m={2}
      bg={bg}
      spacing={3}
      rounded="sm"
      shadow="sm"
      zIndex={10}
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
        w={"100%"}
      />
      {links.map((link) => (
        <Box w="full" key={link.link}>
          <Link to={link.link} w="100%" onClick={() => mobileNav.onClose}>
            <Button w="full" variant="ghost" leftIcon={link.icon}>
              {link.name}
            </Button>
          </Link>
        </Box>
      ))}
      <Box w="full">
        {props.auth.isAuthenticated ? (
          <Button
            w="full"
            variant="ghost"
            colorScheme="red"
            leftIcon={<RiUserUnfollowFill />}
            onClick={() => props.logout()}
          >
            Sign Out
          </Button>
        ) : (
          ""
        )}
      </Box>
    </VStack>
  );
  return (
    <>
      <Container maxW="container.xl" centerContent>
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            bg={"white"}
            me={3}
            px={3}
            rounded="2xl"
            roundedTop="none"
            boxShadow={"xl"}
          >
            <Image src={grpLogo} boxSize="70px" />
          </Box>

          <HStack spacing={2} display={{ base: "none", md: "flex" }}>
            {links.map((link) => (
              <Link to={link.link} key={link.link}>
                <Button
                  variant="ghost"
                  _hover={{ bg: "inherit" }}
                  fontSize="sm"
                  size="sm"
                  textColor="white"
                >
                  {link.name}
                </Button>
              </Link>
            ))}
          </HStack>
          <Spacer />
          <Flex justify="flex-end">
            {props.auth.isAuthenticated ? (
              <Menu isLazy direction="rtl">
                <MenuButton
                  cursor={"pointer"}
                  minW={0}
                  display={{ base: "none", md: "flex" }}
                >
                  <AiOutlineUser color="white" variant="ghost" size={20} />
                </MenuButton>
                <MenuList fontSize={14} minW={0} border="2px solid black">
                  <Link to="/change_password">
                    <MenuItem
                      transition={"500ms"}
                      _hover={{ bg: "pink.100" }}
                      _focus={{ bg: "pink.100" }}
                    >
                      Change Password
                    </MenuItem>
                  </Link>

                  <MenuItem
                    transition={"500ms"}
                    _hover={{ bg: "pink.100" }}
                    _focus={{ bg: "pink.100" }}
                    textColor="red"
                    onClick={() => props.logout()}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              ""
            )}

            <Box
              display={
                props.auth.isAuthenticated
                  ? { base: "flex", md: "none" }
                  : "none"
              }
              onClick={mobileNav.onOpen}
            >
              <AiOutlineMenu size={25} color="white" />
            </Box>
          </Flex>
        </Flex>
        {MobileNavContent}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
