import React from "react";
import * as Yup from "yup";

import { FormikProvider, useFormik } from "formik";

import Question from "../Question";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
} from "@chakra-ui/react";

import { toastAlert } from "../../ToastAlerts";

import { additionalContactQuestions as questions } from "../Variables/questions";

const AdditionalContactModal = ({ isOpen, onClose, addAdditionalContact }) => {
  const schemaObj = {};
  const initialValues = {};

  const determineInitialValues = (type) => {
    switch (type) {
      case "text":
        return "";
      case "fileInput":
        return [];
      case "linkedCheckbox":
        return {
          viewed: false,
          accepted: false,
        };
      default:
        return undefined;
    }
  };

  questions
    .filter((q) => q.type !== "textOnly")
    .forEach((q) => (schemaObj[q.name] = q.yup));

  questions
    .filter((q) => q.type !== "textOnly")
    .forEach((q) => (initialValues[q.name] = determineInitialValues(q.type)));

  const validationSchema = Yup.object().shape(schemaObj);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (newContactData) => {
      addAdditionalContact(newContactData);
      toastAlert(
        "success",
        "Additional Contact Added",
        `${newContactData.first_name} has been added to the contact's list.`
      );
      formik.resetForm();
      onClose();
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader>Add additional contact</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              // style={{
              //   minHeight: "65vh",
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-between",
              // }}
            >
              <Grid
                gap={4}
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(12, 1fr)",
                }}
              >
                {questions.map((question) => (
                  <Question
                    question={question}
                    formik={formik}
                    key={question.name}
                  />
                ))}
              </Grid>
              <ModalFooter m={0} px={0}>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button type="submit" colorScheme={"facebook"}>
                  Add Contact
                </Button>
              </ModalFooter>
            </form>
          </FormikProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdditionalContactModal;
