import React, { useState, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import NET from "vanta/dist/vanta.net.min";

const VantaBackground = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          color: "#F1935C",
          backgroundColor: "#424874",
          mouseControls: false,
          touchControls: false,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          points: 7.5,
          scale: 1.0,
          scaleMobile: 1.0,
        })
      );
    }
    return () => {
      if (vantaEffect) {
        vantaEffect.restart();
      }
    };
  }, [vantaEffect]);

  return <Box ref={myRef} w="100%" h="100%" zIndex="-1" position="fixed"></Box>;
};

export default VantaBackground;
