import Skeleton from "react-loading-skeleton";

const TableSkeleton = () => {
  return (
    <Skeleton
      baseColor="#ffe5ec"
      highlightColor="#ffccda"
      width="100%"
      height={65}
      count={10}
      borderRadius="10px"
      style={{ border: "2px solid black" }}
    />
  );
};

export default TableSkeleton;
