import React, { useState } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { ChangeUserPassword } from "../actions/auth";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import {
  Container,
  Box,
  Heading,
  Button,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import store from "../store";

import { HiEye, HiEyeOff } from "react-icons/hi";

const ChangePassword = (props) => {
  const location = useLocation();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPasswords, setShowNewPasswords] = useState(false);

  store.subscribe(() => {
    console.log("Change");
  });

  const newPasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Please enter your old password"),
    new_password1: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})/,
        "Password must contain 8-64 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .required("Please enter new password"),
    new_password2: Yup.string()
      .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
      .required("Please confirm new password"),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password1: "",
      new_password2: "",
    },
    onSubmit: (values) => {
      props.ChangeUserPassword(values, formik.setSubmitting);
    },
    validationSchema: newPasswordSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <Container
      maxW={{ base: "sm", md: "lg" }}
      py={{ base: "6vh" }}
      px={{ base: "2", sm: "8" }}
    >
      <Box
        py={10}
        px={"10"}
        bg={"white"}
        boxShadow={"xl"}
        borderRadius="xl"
        border="2px solid black"
      >
        <Heading mb={3}>Change Password</Heading>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <Stack spacing="2">
                <FormControl
                  id="old_password"
                  name="old_password"
                  isInvalid={
                    formik.errors.old_password && formik.touched.old_password
                  }
                >
                  <FormLabel htmlFor="old_password">Old Password</FormLabel>
                  <InputGroup>
                    <Input
                      variant={"grpInput"}
                      type={showOldPassword ? "text" : "password"}
                      autoComplete="current-password"
                      value={formik.values.old_password}
                      onChange={formik.handleChange}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        p={3}
                        variant={"ghost"}
                        onClick={() =>
                          setShowOldPassword(
                            (showOldPassword) => !showOldPassword
                          )
                        }
                      >
                        {showOldPassword ? <HiEye /> : <HiEyeOff />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>
                    {formik.errors.old_password}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  variant={"grpInput"}
                  id="new_password1"
                  name="new_password1"
                  isInvalid={
                    formik.errors.new_password1 && formik.touched.new_password1
                  }
                >
                  <FormLabel htmlFor="new_password1">New Password</FormLabel>
                  <InputGroup>
                    <Input
                      variant={"grpInput"}
                      type={showNewPasswords ? "text" : "password"}
                      value={formik.values.new_password1}
                      onChange={formik.handleChange}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        p={3}
                        variant={"ghost"}
                        _hover={{ bg: "inherit" }}
                        onClick={() =>
                          setShowNewPasswords(
                            (showNewPasswords) => !showNewPasswords
                          )
                        }
                      >
                        {showNewPasswords ? <HiEye /> : <HiEyeOff />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>
                    {formik.errors.new_password1}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="new_password2"
                  name="new_password2"
                  isInvalid={
                    formik.errors.new_password2 && formik.touched.new_password2
                  }
                >
                  <FormLabel htmlFor="new_password2">
                    Confirm New Password
                  </FormLabel>
                  <Input
                    variant={"grpInput"}
                    type={showNewPasswords ? "text" : "password"}
                    value={formik.values.new_password2}
                    onChange={formik.handleChange}
                  />

                  <FormErrorMessage>
                    {formik.errors.new_password2}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
            <HStack justifyContent="end" mt={3}>
              <Button
                variant={"grpButton"}
                bg="pink"
                fontWeight="normal"
                type="submit"
                isLoading={formik.isSubmitting}
              >
                Save
              </Button>
            </HStack>
          </form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { ChangeUserPassword })(ChangePassword);
