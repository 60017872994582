import { createStandaloneToast } from "@chakra-ui/react";
import theme from "./Theme";
const toast = createStandaloneToast({ theme });

export const toastAlert = (type, message, description = "") => {
  try {
    toast({
      title: message,
      description: description,
      status: type,
      position: "bottom-right",
      duration: 4000,
      isClosable: true,
    });
  } catch {
    toast({
      title: "Something went wrong...",
      status: type,
      position: "bottom-right",
      duration: 4000,
      isClosable: true,
    });
  }
};
