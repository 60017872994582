import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  VStack,
  Text,
  Badge,
  Spacer,
  Heading,
  chakra,
} from "@chakra-ui/react";

const KPIDailyModal = (props) => {
  const [selectedClinician, setSelectedClinician] = useState({
    name: "",
    daily_kpi: "",
    appointments: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thusday: 0,
      Friday: 0,
    },
  });

  useEffect(() => {
    if (props.selectedClinician) {
      setSelectedClinician(props.selectedClinician);
    }
  }, [props.selectedClinician]);

  const handleModalClose = () => {
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>
          <VStack align="start">
            <Heading fontWeight="medium" fontSize="2xl">
              {selectedClinician.name}
            </Heading>
          </VStack>
        </ModalHeader>

        <ModalCloseButton />
        <HStack px={6}>
          <Text fontSize="sm" color="gray.600">
            Speech Pathologist (Daily KPI: {selectedClinician.daily_kpi})
          </Text>
          <Spacer />
          <Text fontSize="sm" color="gray.500">
            <chakra.span
              color={
                Math.sign(
                  Object.values(selectedClinician.appointments).reduce(
                    (a, b) => a + b
                  ) -
                    selectedClinician.daily_kpi * 5
                ) < 0
                  ? "red"
                  : "green"
              }
            >
              {(
                Object.values(selectedClinician.appointments).reduce(
                  (a, b) => a + b
                ) -
                selectedClinician.daily_kpi * 5
              ).toFixed(2)}
            </chakra.span>
          </Text>
        </HStack>
        <ModalBody px={0} mb={0} my={3}>
          <HStack spacing="auto" px={5}>
            {Object.entries(selectedClinician.appointments).map(
              ([key, value]) => (
                <VStack key={key}>
                  <Text fontWeight="medium">{key}</Text>
                  <Badge
                    colorScheme={
                      value >= selectedClinician.daily_kpi ? "green" : "red"
                    }
                    rounded="full"
                    px={2}
                  >
                    {value.toFixed(2)}
                  </Badge>
                </VStack>
              )
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.system.clinicians,
});

export default connect(mapStateToProps, {})(KPIDailyModal);
