import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsers, clearUsers } from "../actions/system";
import {
  Container,
  HStack,
  VStack,
  Heading,
  Box,
  Button,
  Flex,
  Input,
  Spacer,
} from "@chakra-ui/react";
import UsersTable from "./UsersTable";
import NewUserDrawer from "./NewUserDrawer";

const Users = (props) => {
  const [userDrawerIsOpen, setUserDrawerIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleUserDrawerClose = () => {
    setUserDrawerIsOpen(false);
  };

  useEffect(() => {
    props.clearUsers();
    props.getUsers();
  }, []);

  return (
    <>
      <Container maxW="container.xl" mx="auto">
        <Box mt={6}>
          <HStack spacing={3}>
            <Heading fontSize="5xl" fontWeight={"extrabold"} textColor="white">
              Users
            </Heading>
            <Spacer />
            <HStack>
              <Box>
                <Input
                  type="text"
                  variant="grpPinkInput"
                  placeholder="Search User..."
                  _placeholder={{ textColor: "black" }}
                  w={"150px"}
                  roundedEnd="none"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Search User...")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Box>
              <Button
                variant="grpButton"
                onClick={() => setUserDrawerIsOpen(true)}
                fontWeight="medium"
                textColor="black"
              >
                Add User
              </Button>
            </HStack>
          </HStack>

          <UsersTable searchValue={searchValue} />
        </Box>
      </Container>

      <NewUserDrawer
        userDrawerIsOpen={userDrawerIsOpen}
        userDrawerOnClose={handleUserDrawerClose}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.system.users,
});

export default connect(mapStateToProps, { getUsers, clearUsers })(Users);
