import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { changeAPIKey } from "../actions/auth";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import {
  Container,
  Box,
  Heading,
  Button,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";

const ApiKeyInput = (props) => {
  const location = useLocation();

  const APISchema = Yup.object().shape({
    api_key: Yup.string()
      .required("Please enter API Key"),
  });

  const formik = useFormik({
    initialValues: {
      api_key: "",
    },
    onSubmit: (values) => {
      formik.setSubmitting(true);
      props.changeAPIKey(values, formik.setSubmitting);
    },
    validationSchema: APISchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  if (props.user.api_key_confirmed) {
    return (
      <Navigate
        to={location.state?.from ? location.state.from.pathname : "/kpi"}
      />
    );
  }

  return (
    <Container
      maxW={{ base: "sm", md: "lg" }}
      py={{ base: "6vh" }}
      px={{ base: "2", sm: "8" }}
    >
      <Box
        py={10}
        px={"10"}
        bg={"white"}
        boxShadow={"xl"}
        borderRadius="xl"
        border="2px solid black"
      >
        <Heading mb={1}>Enter API Key</Heading>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl
                  id="api_key"
                  name="api_key"
                  isInvalid={formik.errors.api_key && formik.touched.api_key}
                >
                  <FormLabel htmlFor="api_key">Cliniko API Key</FormLabel>
                  <Input
                    variant={"grpInput"}
                    value={formik.values.api_key}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>
                    Please contact a manager for further information.
                  </FormHelperText>
                  <FormErrorMessage>{formik.errors.api_key}</FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
            <HStack justifyContent="end" mt={3}>
              <Button
                variant="grpButton"
                fontWeight="normal"
                type="submit"
                isLoading={formik.isSubmitting}
              >
                Save
              </Button>
            </HStack>
          </form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { changeAPIKey })(ApiKeyInput);
