import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router";

const StaffRoute = (props) => {
  const location = useLocation();
  return props.auth.isAuthenticated ? (
    [
      props.auth.user.api_key_confirmed ? (
        <Outlet />
      ) : (
        [
          location.pathname !== "/api_key" ? (
            <Navigate to="/api_key" replace state={{ from: location }} />
          ) : (
            <Outlet />
          ),
        ]
      ),
    ]
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(StaffRoute);
