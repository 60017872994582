import { extendTheme } from "@chakra-ui/react";
import "@fontsource/metropolis";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

import { MultiSelectTheme } from "chakra-multiselect";

const theme = extendTheme({
  fonts: {
    heading: "Metropolis",
    body: "Metropolis",
  },

  components: {
    MultiSelect: MultiSelectTheme,
    Input: {
      variants: {
        grpInput: {
          field: {
            borderColor: "black",
            borderWidth: "2px",
            _invalid: { borderColor: "red", borderWidth: "2px" },
          },
        },
        grpPinkInput: {
          field: {
            bg: "pink.100",
            borderColor: "black",
            borderWidth: "2px",
            _focus: {
              borderColor: "#000",
              borderWidth: "2px",
            },
            _disabled: {
              opacity: "0.25",
              cursor: "not-allowed",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        grpSelect: {
          field: {
            borderColor: "black",
            borderWidth: "2px",
            _invalid: { borderColor: "red", borderWidth: "2px" },
          },
        },
      },
    },
    Button: {
      variants: {
        grpButton: {
          bg: "pink.100",
          color: "gray.700",
          border: "2px black solid",
          _hover: { bg: "pink.200" },
          _focus: {
            boxShadow: "0 0 0 3px pink.800",
          },
        },
      },
    },
    Table: {
      variants: {
        grpTable: {
          th: {
            background: "#ffd9e3",
            fontSize: "sm",
            textColor: "orange.500",

            // textShadow:
            //   " -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000,  1px 1px 1px #000;",
          },
          tr: {
            _odd: {
              background: "#ffe5ec",
            },
            _even: {
              background: "#ffd9e3",
            },
            _last: {
              borderRadius: "10px",
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: "pink.50",
          border: "2px solid black",
        },
      },
    },
    Steps,
  },
  // styles: {
  //   global: {
  //     body: {
  //       bg: mainColor,
  //     },
  //   },
  // },
});

export default theme;
