import axios from "axios";

import { toastAlert } from "../components/ToastAlerts";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  API_KEY_CHANGE,
} from "./types";

export const site = "https://services.grpspeechpathology.com.au";

export const loadUser = () => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });

  axios
    .get(`/api/auth/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

export const login = (email, password, setSubmitting) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
    password,
  });

  axios
    .post(`/api/auth/login`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setSubmitting(false);
    })
    .catch((err) => {
      if (!err.response) {
        toastAlert(
          "error",
          "Server Error",
          "No connection to the server. Please refresh the page."
        );
      } else if (err.response.status === 429) {
        toastAlert("error", "Too many requests", err.response.data.detail);
      } else if (err.response.status === 404 || err.response.status === 500) {
        toastAlert(
          "error",
          "Database Offline",
          "Please contact your system admin"
        );
      } else {
        for (let i in err.response.data) {
          toastAlert(
            "error",
            i === "non_field_errors" ? "Operation Failed" : i,
            `${err.response.data[i]}`
          );
        }
      }
      setSubmitting(false);
    });
};

export const changeAPIKey = (data, setSubmitting) => (dispatch, getState) => {
  axios
    .post("/api/api_key/", data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: API_KEY_CHANGE,
        payload: res.data,
      });
      setSubmitting(false);
      toastAlert("success", "API Key Validated", "You API key was accepted!");
    })
    .catch((err) => {
      for (let i in err.response.data) {
        toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
      }
      setSubmitting(false);
    });
};

export const ChangeUserPassword =
  (data, setSubmitting) => (dispatch, getState) => {
    axios
      .put("/api/auth/change_password", data, tokenConfig(getState))
      .then((res) => {
        setSubmitting(false);
        toastAlert(
          "success",
          "Password Changed",
          "Your password has been changed!"
        );
      })
      .catch((err) => {
        for (let i in err.response.data) {
          toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
        }
        setSubmitting(false);
      });
  };

export const logout = () => (dispatch, getState) => {
  axios
    .post("/api/auth/logout", null, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      localStorage.removeItem("token");
    })
    .catch((err) => {
      console.log(err.response.data, err.response.status);
    });
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
