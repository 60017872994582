import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Link,
  HStack,
  Spacer,
  Text,
  ListItem,
  List,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

const LinkCheckBox = ({ question, formik }) => {
  return (
    <FormControl
      id={question.name}
      name={question.name}
      isInvalid={formik.errors[question.name] && formik.submitCount > 0}
    >
      <HStack>
        <HStack>
          <FormLabel m={0}>{question.question}</FormLabel>
          <Link
            isExternal
            href={question.href}
            color="blue.500"
            onClick={() =>
              formik.setFieldValue(question.name, {
                ...formik.values[question.name],
                viewed: true,
              })
            }
          >
            {question.link}
          </Link>
        </HStack>

        <Spacer />
        <Checkbox
          onChange={(e) => {
            formik.setFieldValue(question.name, {
              ...formik.values[question.name],
              accepted: e.target.checked,
            });
          }}
          isChecked={formik.values[question.name].accepted}
        />
      </HStack>
      <FormErrorMessage>
        <List>
          {formik.errors[question.name] &&
            Object.keys(formik.errors[question.name]).map((err) => (
              <ListItem key={formik.errors[question.name][err]}>
                {formik.errors[question.name][err]}
              </ListItem>
            ))}
        </List>
      </FormErrorMessage>
      <FormHelperText>{question.helpText}</FormHelperText>
    </FormControl>
  );
};

export default LinkCheckBox;
