import { toastAlert } from "../components/ToastAlerts";
import { tokenConfig } from "./auth";
import {
  GET_CLINICIANS,
  GET_USERS,
  ADD_USER,
  LINK_CLINICIAN,
  UPDATE_CLINICIAN,
  GET_APPOINTMENTS,
  CLEAR_APPOINTMENTS,
  CLEAR_CLINICIANS,
  CLEAR_USERS,
  UPDATE_USER,
  AUTH_ERROR,
} from "./types";

import axios from "axios";

import { site } from "./auth";

export const getClinicians = () => (dispatch, getState) => {
  axios
    .get(`/api/list_clinicians`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLINICIANS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else {
        toastAlert(
          "error",
          "Failed Operation",
          "Could not retrieve clinician details"
        );
      }
    });
};

export const getUsers = () => (dispatch, getState) => {
  axios
    .get(`/api/users/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else {
        toastAlert(
          "error",
          "Failed Operation",
          "Could not retrieve user details"
        );
      }
    });
};

export const addUser = (data, handleModalClose) => (dispatch, getState) => {
  axios
    .post(`/api/users/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
      toastAlert("success", "User Added");
      handleModalClose();
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else {
        for (let i in err.response.data) {
          toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
        }
      }
    });
};

export const updateUser = (data, handleModalClose) => (dispatch, getState) => {
  data.active = !data.active;

  axios
    .put(`/api/users/${data.id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      toastAlert("success", "User Updated");
      handleModalClose();
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else {
        for (let i in err.response.data) {
          toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
        }
      }
    });
};

export const linkClinician =
  (data, handleModalClose) => (dispatch, getState) => {
    axios
      .post(`/api/clinicians/`, data, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: LINK_CLINICIAN,
          payload: res.data,
        });
        toastAlert("success", "Clinician Linked");
        handleModalClose();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({ type: AUTH_ERROR });
        } else {
          for (let i in err.response.data) {
            toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
          }
        }
      });
  };

export const updateClinican =
  (data, handleModalClose) => (dispatch, getState) => {
    axios
      .put(`/api/clinicians/${data.cliniko_id}/`, data, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_CLINICIAN,
          payload: res.data,
        });
        toastAlert("success", "Clinician Updated");
        handleModalClose();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({ type: AUTH_ERROR });
        } else {
          for (let i in err.response.data) {
            toastAlert("error", "Operation Failed", `${err.response.data[i]}`);
          }
        }
      });
  };

export const getAppointments = (week) => (dispatch, getState) => {
  axios
    .get(`/api/appointments?w=${week}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_APPOINTMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else {
        toastAlert(
          "error",
          "Failed Operation",
          "Could not retrieve clinician details"
        );
      }
    });
};

export const clearAppointments = () => (dispatch) =>
  dispatch({
    type: CLEAR_APPOINTMENTS,
  });

export const clearClinicians = () => (dispatch) =>
  dispatch({
    type: CLEAR_CLINICIANS,
  });

export const clearUsers = () => (dispatch) =>
  dispatch({
    type: CLEAR_USERS,
  });
