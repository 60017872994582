import { CheckIcon } from "@chakra-ui/icons";
import {
    Heading,
    Spinner,
    Text,
    Stack,
    Center,
    Box,
    Button,
    Link,
    Image,
} from "@chakra-ui/react";
import React from "react";

import teamGRP from "../../images/Team_GRP.jpg";

const FormSubmissionPage = ({ submitStatus }) => {
    return (
        <Center minH={"100%"}>
            <Box>
                {submitStatus.submitting && !submitStatus.submitted
                    ? loadingScreen(submitStatus.submitMessage)
                    : submittedScreen()}
            </Box>
        </Center>
    );
};

const loadingScreen = (submitMessage) => (
    <Stack gap={4} align="center">
        <Spinner
            size={"xl"}
            boxSize={200}
            borderWidth="3px"
            color="facebook.500"
            mb={5}
        />
        <Heading fontWeight={"medium"}>Submitting...</Heading>
        <Text textAlign={"center"} maxW="80%" fontSize="lg">
            {submitMessage || `Don't worry, we've got you... This form takes 10-15 seconds to
            process so even if you think nothing is happening, we are working
            hard behind the scenes for you to get your form submitted ASAP. Sit
            tight and whatever you do, don't exit this page!`}
        </Text>
    </Stack>
);

const submittedScreen = () => (
    <Stack align="center">
        <Center px={10} pt={10}>
            <Image src={teamGRP} rounded="xl" boxShadow={"inner"} maxH="40vh" />
        </Center>

        <Stack align="center" py={5} gap={2}>
            <Heading fontWeight={"medium"} m={0}>
                Congratulations!
            </Heading>
            <Text
                textAlign={"center"}
                maxW={{ base: "100%", md: "80%" }}
                fontSize="lg"
                px={3}
            >
                You've taken your first step towards kicking your Speech Pathology goals at GRP!
            </Text>
            <Text
                textAlign={"center"}
                maxW={{ base: "100%", md: "80%" }}
                fontSize="lg"
                px={3}
            >
                We can't wait to work with you!
            </Text>
            <Text
                textAlign={"center"}
                maxW={{ base: "100%", md: "80%" }}
                fontSize="lg"
                px={3}
            >
                One of our trusty team members behind the scenes will be in touch as soon as possible.
            </Text>
            <Text
                textAlign={"center"}
                maxW={{ base: "100%", md: "80%" }}
                fontSize="lg"
                px={3}
            >
                Have a great day! 🌻
            </Text>
            <Link
                href="https://www.grpspeechpathology.com.au/"
                _hover={{ textDecoration: "none" }}
            >
                <Button colorScheme="facebook">
                    Return to GRP main website
                </Button>
            </Link>
        </Stack>
    </Stack>
);

export default FormSubmissionPage;
